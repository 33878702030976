import { Box, Heading, Image, useToast } from "@chakra-ui/react";
import LockImage from "../assets/images/lock.png";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { deleteStorage, getLocalStorage } from "utils";
import service from "service/service";
import { signOut } from "firebase/auth";
import { persistor } from "app/store";
import endpoint from "service/constUrl";
import { authentication } from "../firebase/config";

const Unauthorized = () => {
  const toast = useToast();
  const [user] = useState(() => getLocalStorage("initialData"));
  const { state } = useLocation();

  const isUnauthorized = !state?.from?.state?.allowedRoles?.includes(
    user?.role[0]
  );

  const logOut = async () => {
    await service.post(`${endpoint.BASE_URL}/logout`);
    await signOut(authentication);
    deleteStorage("token");
    deleteStorage("initialData");
    persistor.pause();
    persistor.flush().then(() => {
      persistor.purge();
      window.location.href = "/auth";
    });
  };

  useEffect(() => {
    toast({
      title: "User not found",
      status: "error",
      position: "top-right",
    });
    if (isUnauthorized) {
      logOut();
    }
  }, [isUnauthorized]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
    >
      <Box>
        <Image src={LockImage} />
        <Heading mt={5} textAlign="center">
          Unauthorized
        </Heading>
      </Box>
    </Box>
  );
};

export default Unauthorized;
