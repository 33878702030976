import { Flex } from "@chakra-ui/react";
import React from "react";
import { UseMultistepForm } from "../../../../hooks/useMultiStepForm";
import { useUserAuth } from "../../../../context/AuthProvider";
import CreatePassword from "../CreatePassword";
import InputOtp from "../InputOtp";
import Greeting from "../Greeting";
import CreateProfile from "../CreateProfile";
import Thanks from "../Thanks";

import {
  goToInputOtp,
  goToGreeting,
  handleNext,
} from "../../../../hooks/stepHandlers";
import { useRootContext } from "../../../../context/RootContext";

const Index = ({ isGoogleAccount, googleAccount, setGoogleAccount, fcmToken }) => {
  const { initialData, setInitialData } = useUserAuth();
  const { setCreateProfileState } = useRootContext();

  const updateFields = (fields) => {
    setInitialData((prev) => {
      return { ...prev, ...fields };
    });
  };

  let steps = [<Thanks key={4} />];

  if (isGoogleAccount) {
    steps.unshift(
      <CreateProfile
        key={3}
        {...initialData}
        updateFields={updateFields}
        handleNext={() => handleNext(next, isLastStep)}
        isGoogleAccount={isGoogleAccount}
        googleAccount={googleAccount}
        setGoogleAccount={setGoogleAccount}
        fcmToken={fcmToken}
      />
    );
  } else {
    steps.unshift(
      <CreatePassword
        key={0}
        {...initialData}
        updateFields={updateFields}
        handleNext={() => goToInputOtp(currentStepIndex, goTo)}
      />,
      <InputOtp
        key={1}
        handleNext={() => goToGreeting(currentStepIndex, goTo)}
      />,
      <Greeting
        key={2}
        handleNext={() => {
          handleNext(next, isLastStep);
          setCreateProfileState(true);
        }}
      />,
      <CreateProfile
        key={3}
        {...initialData}
        updateFields={updateFields}
        fcmToken={fcmToken}
        handleNext={() => handleNext(next, isLastStep)}
      />
    );
  }

  const { step, currentStepIndex, goTo, next, isLastStep } =
    UseMultistepForm(steps);

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      width={{ mini: "100%", tablet: "100%" }}
      height={{ mini: "100vh", tablet: "auto" }}
      padding={{ mini: "0 40px", tablet: "40px 0" }}
      bg={
        currentStepIndex === 2 || currentStepIndex === 4 ? "#B3DDCD" : "#B3DDCD"
      }
    >
      {step}
    </Flex>
  );
};

export default Index;
