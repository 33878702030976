import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import React, { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const CreatePassword = ({ handleNext, updateFields }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const auth = useSelector((state) => state.auth);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    if (!errors.password) {
      updateFields({ password: watch("password") });
    }

    if (!auth.loading) {
      handleNext();
    }
  };

  // console.log({ handleNext, auth });

  return (
    <>
      <VStack alignItems={"normal"}>
        <Box width={{mini:"625px",tablet:"625px",mobile:"100%"}} p={{mini:"0px",tablet:"0px",mobile:"20px 20px"}}>
          <Text pb={3} fontSize={"26px"} lineHeight={"20px"} fontWeight={600}>
            Create Password
          </Text>

          <Text
            fontSize={"16px"}
            lineHeight={"16px"}
            fontWeight={400}
            color={"#737373"}
          >
            Create your account password
          </Text>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems={"normal"} width={{mini:"625px",tablet:"625px",mobile:"100%"}} spacing={8} p={{mini:"0px",tablet:"0px",mobile:"0px 20px"}}>
            <FormControl isInvalid={errors.password}>
              <FormLabel fontSize={"20px"} pt={8} pb={5}>
                Password
              </FormLabel>

              <InputGroup display="flex" alignItems="center" gap={5}>
                <Input
                  {...register("password", {
                    required: "This fields cannot be empty!",
                  })}
                  id="password"
                  placeholder="Enter 8 character password"
                  px={8}
                  backgroundColor={"#EDEBEF"}
                  border={"1px"}
                  borderColor={"#60A78D"}
                  height={"50px"}
                  borderRadius={"50px"}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    updateFields({ password: watch("password") });
                    setPassword(e.target.value);
                  }}
                />

                <InputRightElement height={"100%"} pr={8}>
                  <Icon
                    as={showPassword ? AiOutlineEyeInvisible : AiOutlineEye}
                    w={8}
                    h={8}
                    cursor="pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>

              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>

            <Box
              padding={5}
              width={"max-content"}
              backgroundColor={"white"}
              borderRadius={"10px"}
              boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            >
              <Text fontWeight={600} fontSize={"16px"}>
                Your password must contain:
              </Text>

              <PasswordChecklist
                rules={["minLength", "number", "capital", "lowercase", "match"]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                messages={{
                  minLength: "a minimum of 8 characters",
                  number: "a minimum of 1 number",
                  lowercase: "a minimum of 1 lowercase letter",
                  capital: "a minimum of 1 uppercase letter",
                  match: "password didn't match",
                }}
              />
            </Box>

            <FormControl isInvalid={errors.password}>
              <FormLabel fontSize={"20px"} pb={5}>
                Retype Password
              </FormLabel>

              <InputGroup display="flex" alignItems="center" gap={5}>
                <Input
                  {...register("passwordAgain", {
                    required: "This fields cannot be empty!",
                  })}
                  id="repeat-password"
                  placeholder="Enter 8 character password"
                  px={8}
                  backgroundColor={"#EDEBEF"}
                  border={"1px"}
                  borderColor={"#60A78D"}
                  height={"50px"}
                  borderRadius={"50px"}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    updateFields({ password: watch("password") });
                    setPasswordAgain(e.target.value);
                  }}
                />

                <InputRightElement height={"100%"} pr={8}>
                  <Icon
                    as={showPassword ? AiOutlineEyeInvisible : AiOutlineEye}
                    w={8}
                    h={8}
                    cursor="pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>

              <FormErrorMessage>
                {errors.passwordAgain && errors.passwordAgain.message}
              </FormErrorMessage>
            </FormControl>

            <Text textAlign={"center"}>
              By continuing, you agree and accept to Seeds{" "}
              <Text as={"span"} fontWeight={700}>
                Terms of Service
              </Text>{" "}
              and acknowledge that you have read and accept our{" "}
              <Text as={"span"} fontWeight={700}>
                Privacy Policy.
              </Text>{" "}
            </Text>

            <Button
              type="submit"
              isLoading={auth?.loading}
              loadingText="Submitting"
              height={"50px"}
              borderRadius={"50px"}
              colorScheme="green"
              backgroundColor={"#60A78D"}
            >
              Continue
            </Button>
          </VStack>
        </form>
      </VStack>
    </>
  );
};

export default CreatePassword;
