import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import DevelopmentImage from "../assets/images/dev.png";

const Development = () => {
  return (
    <Flex alignItems={"center"} justifyContent={"center"} width={"100%"} height={"750px"}>
      <Box>
        <Image src={DevelopmentImage} width={"400px"} />

        <Text textAlign={"center"} fontSize={"2xl"} fontWeight={600}>
          This page is under development{" "}
        </Text>
      </Box>
    </Flex>
  );
};

export default Development;
