import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../../service/service";
import endpoint from "../../../service/constUrl";

// export const getCampaigns = createAsyncThunk("CAMPAIGN/GET_CAMPAIGN", async (_, { rejectWithValue }) => {
//   try {
//     const response = await service.get(`${endpoint.BASE_URL}/`);
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     return rejectWithValue(error.response.data);
//   }
// });

export const createCampaigns = createAsyncThunk(
  "CAMPAIGN/CREATE_CAMPAIGN",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(`${endpoint.BASE_URL}/merchant/voucher`, initialState);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
