import { Box, Spinner } from "@chakra-ui/react";
import React from "react";
// import { ErrorImage } from "../index";

const AppLoading = () => {
  return (
    <Box width={"100%"} height="100vh" display="flex" justifyContent="center" alignItems={"center"}>
      <Box>
        <Spinner thickness="5px" speed="0.65s" emptyColor="gray.200" color="#60A78D" size="xl" />
      </Box>
    </Box>
  );
};

export default AppLoading;
