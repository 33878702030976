import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../../service/service";
import endpoint from "../../../service/constUrl";

export const sendOtp = createAsyncThunk(
  "AUTH/SENDOTP",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/sendmail`,
        initialState
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "AUTH/VERIFYOTP",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/verify_otp`,
        initialState
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSignUp = createAsyncThunk(
  "AUTH/SIGNUP",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.postMultipart(
        `${endpoint.BASE_URL}/signUp`,
        initialState
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSignIn = createAsyncThunk(
  "AUTH/SIGNIN",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.postWithoutToken(
        `${endpoint.BASE_URL}/login`,
        initialState
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "AUTH/UPDATE_USER",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.putMultipartWithToken(
        `${endpoint.BASE_URL}/profile`,
        initialState
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
