import { Box, Flex, Heading, Icon, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../utils";
import { clearSidebar } from "../../app/features/sidebarSlice";
import merchantRoutes from "../../routes/merchantRoutes";
import adminRoutes from "../../routes/adminRoutes";
import organizationRoutes from "../../routes/organizationRoutes";

const MenuItems = () => {
  const [userRole] = useState(() => getLocalStorage("initialData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sidebar = useSelector((state) => state.sideMenu);

  const getMenu = () => {
    const menu = [];

    if (userRole?.role[0] === "SUPERADMIN") {
      for (let index = 0; index < adminRoutes.length; index++) {
        const element = adminRoutes[index];
        if (
          element.roles.find(
            (role) => userRole.role.includes(role) && element.menu === true
          )
        ) {
          menu.push(element);
        }
      }
    }

    if (userRole?.role[0] === "MERCHANT") {
      for (let index = 0; index < merchantRoutes.length; index++) {
        const element = merchantRoutes[index];
        if (
          element.roles.find(
            (role) => userRole.role.includes(role) && element.menu === true
          )
        ) {
          menu.push(element);
        }
      }
    }

    if (userRole?.role[0] === "INSTITUTION") {
      for (let index = 0; index < organizationRoutes.length; index++) {
        const element = organizationRoutes[index];
        if (
          element.roles.find(
            (role) => userRole.role.includes(role) && element.menu === true
          )
        ) {
          menu.push(element);
        }
      }
    }

    return menu;
  };

  return (
    <VStack>
      {getMenu().map((item, key) => (
        <Box
          key={key}
          pos={"relative"}
          width={"100%"}
          cursor={"pointer"}
          onClick={() => {
            navigate(item.path);
            dispatch(clearSidebar());
          }}
          color={
            location.pathname === item.path ||
            sidebar.initialName === item.initialName
              ? "#717579"
              : "#717579"
          }
          backgroundColor={
            location.pathname === item.path ||
            sidebar.initialName === item.initialName
              ? "rgba(96, 167, 141, .2)"
              : "transparent"
          }
          borderRadius={"0px 10px 50px 0px"}
          _before={
            location.pathname === item.path ||
            sidebar.initialName === item.initialName
              ? {
                  content: '""',
                  pos: "absolute",
                  width: "10px",
                  height: "100%",
                  backgroundColor: "#60A78D",
                  borderRadius: "0px 57px 57px 0px",
                }
              : null
          }
        >
          <Flex
            paddingY={{ laptop: 5, mini: 3, tablet: 3, mobile: 3 }}
            gap={8}
            alignItems={"center"}
            pl={14}
            width={"100%"}
          >
            <Icon
              as={item.icon}
              w={{ laptop: 6, mini: 5, mobile: 5 }}
              h={{ laptop: 6, mini: 5, mobile: 5 }}
            />

            <Heading
              fontWeight={
                location.pathname === item.path ||
                sidebar.initialName === item.initialName
                  ? 600
                  : 500
              }
              fontSize={{ laptop: "18px", mini: "md", tablet: "md",mobile: "md" }}
            >
              {item.name}
            </Heading>
          </Flex>
        </Box>
      ))}
    </VStack>
  );
};

export default MenuItems;
