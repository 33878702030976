import { createSlice } from "@reduxjs/toolkit";
import {
  getMerchantMembers,
  getMerchants,
  getOutlets,
} from "./merchantService";

const initialState = {
  loading: null,
  data: [],
  outlets: [],
};

export const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    clearMerchant: (state) => {
      state.data = [];
      state.outlets = [];
      state.merchantDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // GET MERCHANTS
      .addCase(getMerchants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMerchants.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getMerchants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // GET OUTLETS
      .addCase(getOutlets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOutlets.fulfilled, (state, action) => {
        state.loading = false;
        state.outlets = action.payload;
      })
      .addCase(getOutlets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // GET MERCHANT DETAILS
      .addCase(getMerchantMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMerchantMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getMerchantMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearMerchant } = merchantSlice.actions;

export default merchantSlice.reducer;
