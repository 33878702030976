import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import "../src/assets/styles/index.css";
import theme from "./assets/styles/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { ChatContextProvider } from "./context/ChatContex";
import { HelmetProvider } from "react-helmet-async";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { RootContextProvider } from "./context/RootContext";
import { ErrorBoundary } from "react-app-error-boundary";
import AppError from "./components/Error/AppError";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary fallbackRender={AppError}>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <RootContextProvider>
              <ChatContextProvider>
                <HelmetProvider>
                  <Routes>
                    <Route path="/*" element={<App />} />
                  </Routes>
                </HelmetProvider>
              </ChatContextProvider>
            </RootContextProvider>
          </AuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </ErrorBoundary>
);
