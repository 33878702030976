import React, { useEffect, useRef, useState } from "react";
import { useUserAuth } from "../../../context/AuthProvider";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { authSignUp } from "../../../app/features/auth/authService";
import { useRootContext } from "../../../context/RootContext";
import { clearData } from "../../../app/features/auth/authSlice";
import { setLocalStorage } from "../../../utils";
import { getUser } from "../../../app/features/user/userService";
import FormMerchant from "./FormMerchant";
import FormOrganization from "./FormOrganization";
import { Box, Text, useToast } from "@chakra-ui/react";
import moment from "moment";

const CreateProfile = ({
  updateFields,
  handleNext,
  isGoogleAccount,
  googleAccount,
  setGoogleAccount,
  fcmToken
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [token, setToken] = useState(null);
  const { initialData } = useUserAuth();
  const { setCreateProfileState } = useRootContext();
  const [thisDeviceLat, setThisDeviceLat] = useState("");
  const [thisDeviceLon, setThisDeviceLon] = useState("");

  const logoRef = useRef();
  const bannerRef = useRef();
  const documentRef = useRef();

  const auth = useSelector((state) => state.auth);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({ defaultValues: { country: "SG" } });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const [Lat, Lon] = [
            position.coords.latitude,
            position.coords.longitude,
          ];
          setThisDeviceLat(Lat);
          setThisDeviceLon(Lon);
        },
        () => {},
        { enableHighAccuracy: true }
      );
    }
  }, []);

  const onSubmit = () => {
    const payloadMerhcant = new FormData();
    payloadMerhcant.append("address", watch("address.0"));
    payloadMerhcant.append("address", watch("address.1"));
    payloadMerhcant.append("latitude", thisDeviceLat);
    payloadMerhcant.append("longitude", thisDeviceLon);
    payloadMerhcant.append("postcode", watch("postalcode"));
    payloadMerhcant.append("fcm_token", fcmToken);
    payloadMerhcant.append("email", initialData.email);
    payloadMerhcant.append("password", initialData.password);
    payloadMerhcant.append("roles", initialData.roles);
    payloadMerhcant.append("photo", watch("photo"));
    payloadMerhcant.append("bussiness_no", watch("bussiness_no"));
    payloadMerhcant.append("bussiness_name", watch("business_name"));
    payloadMerhcant.append("brand_name", watch("brand_name"));
    payloadMerhcant.append("date_establishment", moment(watch("date")).toISOString());
    payloadMerhcant.append("bussiness_doc", watch("document"));
    payloadMerhcant.append("country", watch("country"));
    payloadMerhcant.append("business_type", watch("business_type"));
    payloadMerhcant.append("number_outlet", watch("number_outlet"));
    payloadMerhcant.append("other", watch("email_or_contact"));
    payloadMerhcant.append("bio", watch("business_profile"));
    payloadMerhcant.append("website", initialData.website);
    payloadMerhcant.append("link", initialData.link);
    payloadMerhcant.append("phonenumber", `+${watch("phonenumber")}`);
    isGoogleAccount
      ? payloadMerhcant.append("signup_provider", "Google")
      : payloadMerhcant.append("signup_provider", "API");
    isGoogleAccount
      ? payloadMerhcant.append("data_provider", JSON.stringify(googleAccount))
      : payloadMerhcant.append("data_provider", null);

    const payloadOrganization = new FormData();
    payloadOrganization.append("latitude", thisDeviceLat);
    payloadOrganization.append("longitude", thisDeviceLon);
    payloadOrganization.append("fcm_token", fcmToken);
    payloadOrganization.append("email", initialData.email);
    payloadOrganization.append("password", initialData.password);
    payloadOrganization.append("roles", initialData.roles);
    payloadOrganization.append("photo", watch("photo"));
    payloadOrganization.append("banner", watch("banner"));
    payloadOrganization.append("bussiness_name", watch("organization_name"));
    payloadOrganization.append("date_establishment", moment(watch("date")).toISOString());
    payloadOrganization.append("bussiness_doc", watch("document"));
    payloadOrganization.append("country", watch("country"));
    payloadOrganization.append("institution", watch("institution"));
    payloadOrganization.append("phonenumber", `+${watch("phonenumber")}`);
    payloadOrganization.append("preference", watch("preference"));
    payloadOrganization.append("other", watch("email_or_contact"));
    payloadOrganization.append("bio", watch("organization_profile"));
    payloadOrganization.append("bussiness_no", watch("bussiness_no"));
    payloadOrganization.append("address", watch("address.0"));
    payloadOrganization.append("address", watch("address.1"));
    payloadOrganization.append("postcode", watch("postalcode"));
    payloadOrganization.append("website", initialData.website);
    payloadOrganization.append("link", initialData.link);
    isGoogleAccount
      ? payloadOrganization.append("signup_provider", "Google")
      : payloadOrganization.append("signup_provider", "API");
    isGoogleAccount
      ? payloadOrganization.append("data_provider", JSON.stringify(googleAccount))
      : payloadOrganization.append("data_provider", null);

    if (initialData.roles === "MERCHANT") {
      dispatch(authSignUp(payloadMerhcant));
    } else if (initialData.roles === "INSTITUTION") {
      dispatch(authSignUp(payloadOrganization));
    }
  };

  useEffect(() => {
    if (auth.data?.status === "success") {
      setLocalStorage("initialData", {
        role: [initialData.roles],
      });
      setLocalStorage("token", auth?.data?.data?.secret_token);
      setToken(auth?.data?.data?.secret_token);
    }
  }, [auth.data]);

  useEffect(() => {
    if (token !== null) {
      dispatch(getUser(token));
      const timer = setTimeout(() => {
        dispatch(clearData());
        setCreateProfileState(false);
        handleNext();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (auth.data?.status === "error") {
      toast({
        duration: 5000,
        position: "bottom-right",
        render: () => (
          <Box
            mb={20}
            bg={"#EDEBEF"}
            color={"#FF7676"}
            borderRadius={"10px"}
            padding={4}
            fontWeight={600}
            border={"1px"}
            borderColor={"#FF7676"}
          >
            <Text>There&apos;s an error when sign up,</Text>
          </Box>
        ),
      });
    }
  }, [auth.data]);

  return (
    <>
      {initialData.roles === "MERCHANT" ? (
        <FormMerchant
          register={register}
          handleSubmit={handleSubmit(onSubmit)}
          errors={errors}
          logoRef={logoRef}
          documentRef={documentRef}
          updateFields={updateFields}
          control={control}
          setValue={setValue}
        />
      ) : (
        <FormOrganization
          register={register}
          handleSubmit={handleSubmit(onSubmit)}
          errors={errors}
          logoRef={logoRef}
          bannerRef={bannerRef}
          documentRef={documentRef}
          updateFields={updateFields}
          control={control}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default CreateProfile;
