import { createContext, useContext, useState } from "react";
// import { authentication } from "../firebase/config";
// import { onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // const [currentUser, setCurrentUser] = useState({});
  const [error, setError] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [initialData, setInitialData] = useState({});

  // const setUpRecaptha = (number) => {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //       },
  //       authentication
  //     );
  //   }

  //   window.recaptchaVerifier.render();
  //   return signInWithPhoneNumber(authentication, number, window.recaptchaVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //       setIsExpand(true);
  //       setError(false);
  //     })
  //     .catch((error) => {
  //       setIsExpand(false);
  //       setError(true);
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       window.recaptchaVerifier.recaptcha.reset(window.recaptchaVerifier.widgetId);
  //     });
  // };

  // useEffect(() => {
  //   const unsub = onAuthStateChanged(authentication, (user) => {
  //     setCurrentUser(user);
  //   });

  //   return () => {
  //     unsub();
  //   };
  // }, []);

  // console.log(initialData);

  return (
    <AuthContext.Provider
      value={{
        initialData,
        setInitialData,
        isExpand,
        setIsExpand,
        error,
        setError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// export default AuthContext;
export function useUserAuth() {
  return useContext(AuthContext);
}
