import { lazy } from "react";
import { retry } from "utils";
import { HiHome } from "react-icons/hi";
import { BiSolidGift } from "react-icons/bi";
import { BsFillLightningFill } from "react-icons/bs";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import {
  IoSettingsSharp,
  IoHandLeftSharp,
  IoStatsChartSharp,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import { MdVolunteerActivism } from "react-icons/md";
import { FaDonate } from "react-icons/fa";

const organizationRoutes = [
  {
    name: "Dashboard",
    initialName: "/org/dashboard",
    path: "/dashboard",
    component: lazy(() => retry(() => import("pages/institution/dashboard"))),
    roles: ["INSTITUTION"],
    menu: true,
    icon: HiHome,
  },
  {
    name: "Events",
    initialName: "/institution/events",
    path: "/institution/events",
    component: lazy(() =>
      retry(() => import("pages/institution/events/Listings"))
    ),
    roles: ["INSTITUTION"],
    menu: true,
    icon: BsFillLightningFill,
  },
  {
    name: "EventsVerifyHour",
    initialName: "/institution/events/verify-hour",
    path: "/institution/events/verify-hour",
    component: lazy(() =>
      retry(() => import("pages/institution/events/Listings/VerifyHour"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "EventsStatistics",
    initialName: "/institution/events/statistics",
    path: "/institution/events/statistics",
    component: lazy(() =>
      retry(() => import("pages/institution/events/Statistics"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "NewEvent",
    initialName: "/institution/new-event",
    path: "/institution/new-event",
    component: lazy(() =>
      retry(() => import("pages/institution/events/NewEvent/NewVolunteerEvent"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "NewLnDEvent",
    initialName: "/institution/new-lnd-event",
    path: "/institution/new-lnd-event",
    component: lazy(() =>
      retry(() => import("pages/institution/events/NewEvent/NewLnDEvent"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "EditEvent",
    initialName: "/institution/edit-event/:id",
    path: "/institution/edit-event/:id",
    component: lazy(() =>
      retry(() => import("pages/institution/events/NewEvent/NewVolunteerEvent"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "EditLnDEvent",
    initialName: "/institution/edit-lnd-event/:id",
    path: "/institution/edit-lnd-event/:id",
    component: lazy(() =>
      retry(() => import("pages/institution/events/NewEvent/NewLnDEvent"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Event Details",
    initialName: "/institution/events/:id",
    path: "/institution/events/:id",
    component: lazy(() =>
      retry(() => import("pages/institution/events/EventDetails"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Applied Volunteers",
    initialName: "/institution/events/:id/applied-volunteers",
    path: "/institution/events/:id/applied-volunteers",
    component: lazy(() =>
      retry(() => import("pages/institution/events/AppliedVolunteers"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Event Feedbacks",
    initialName: "/institution/events/:id/feedbacks",
    path: "/institution/events/:id/feedbacks",
    component: lazy(() =>
      retry(() => import("pages/institution/events/Feedbacks"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Attendance Volunteers",
    initialName: "/institution/events/:id/attendance-volunteers",
    path: "/institution/events/:id/attendance-volunteers",
    component: lazy(() =>
      retry(() => import("pages/institution/events/AttendanceVolunteers"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Notification",
    initialName: "/institution/notification",
    path: "/institution/notification",
    component: lazy(() => retry(() => import("../pages/notification/index"))),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Volunteer",
    initialName: "/institution/volunteer",
    path: "/institution/volunteer",
    component: lazy(() => retry(() => import("pages/volunteer_org"))),
    roles: ["INSTITUTION"],
    menu: true,
    icon: IoHandLeftSharp,
  },
  {
    name: "Add Volunteer",
    initialName: "/institution/volunteer/add",
    path: "/institution/volunteer/add",
    component: lazy(() =>
      retry(() => import("pages/institution/volunteer/AddVolunteers"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Barter",
    initialName: "/institution/barter",
    path: "/institution/barter",
    component: lazy(() => retry(() => import("pages/institution/barter"))),
    roles: ["INSTITUTION"],
    menu: true,
    icon: MdVolunteerActivism,
  },
  {
    name: "Add Barter",
    initialName: "/institution/barter/add",
    path: "/institution/barter/add",
    component: lazy(() =>
      retry(() => import("pages/institution/barter/create"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Barter Chat",
    initialName: "/institution/barter/chat",
    path: "/institution/barter/chat",
    component: lazy(() => retry(() => import("pages/institution/barter/chat"))),
    roles: ["INSTITUTION"],
    menu: false,
    icon: MdVolunteerActivism,
  },
  {
    name: "Report",
    initialName: "/institution/report",
    path: "/institution/report",
    component: lazy(() => retry(() => import("pages/institution/reporting"))),
    roles: ["INSTITUTION"],
    menu: true,
    icon: IoStatsChartSharp,
  },
  {
    name: "Invoice",
    initialName: "/institution/invoices",
    path: "/institution/invoices",
    component: lazy(() =>
      retry(() => import("pages/institution/invoices/InstitutionInvoices"))
    ),
    roles: ["INSTITUTION"],
    menu: true,
    icon: LiaFileInvoiceSolid,
  },
  {
    name: "Giving Pool",
    initialName: "/institution/giving-pool",
    path: "/institution/giving-pool",
    component: lazy(() => retry(() => import("pages/institution/giving_pool"))),
    roles: ["INSTITUTION"],
    menu: true,
    icon: BiSolidGift,
  },
  {
    name: "Fundraising",
    initialName: "/institution/fundraising",
    path: "/institution/fundraising",
    component: lazy(() => retry(() => import("pages/institution/fundraising"))),
    roles: ["INSTITUTION"],
    menu: true,
    icon: FaDonate,
  },
  {
    name: "List Of Donors",
    initialName: "/institution/fundraising/donors",
    path: "/institution/fundraising/donors",
    component: lazy(() =>
      retry(() => import("pages/institution/fundraising/detail/DonorListPage"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Detail Campaign",
    initialName: "/institution/fundraising/detailCampaign",
    path: "/institution/fundraising/detailCampaign",
    component: lazy(() =>
      retry(() => import("pages/institution/fundraising/detail/DetailCampaign"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Create Campaign",
    initialName: "/institution/fundraising/createCampaign",
    path: "/institution/fundraising/createCampaign",
    component: lazy(() =>
      retry(() => import("pages/institution/fundraising/createCampagin"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Edit Campaign",
    initialName: "/institution/fundraising/editCampaign",
    path: "/institution/fundraising/editCampaign",
    component: lazy(() =>
      retry(() => import("pages/institution/fundraising/edit"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Duplicate Campaign",
    initialName: "/institution/fundraising/duplicateCampaign",
    path: "/institution/fundraising/duplicateCampaign",
    component: lazy(() =>
      retry(() => import("pages/institution/fundraising/duplicate"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  // {
  //   name: "Chat",
  //   initialName: "/institution/chat",
  //   path: "/institution/chat",
  //   component: lazy(() => retry(() => import("pages/dashboard"))),
  //   roles: ["INSTITUTION"],
  //   menu: true,
  //   icon: BsFillChatSquareTextFill,
  // },
  {
    name: "Chat",
    initialName: "/chat",
    path: "/chat",
    component: lazy(() => retry(() => import("../pages/messaging/chatbox"))),
    roles: ["INSTITUTION", "ADMIN", "SUPERADMIN"],
    menu: true,
    icon: IoChatboxEllipsesOutline,
  },
  {
    name: "New Chat",
    initialName: "/newChat",
    path: "/newChat",
    component: lazy(() => retry(() => import("../pages/messaging/newChatbox"))),
    roles: ["INSTITUTION", "ADMIN", "SUPERADMIN"],
    menu: false,
    icon: IoChatboxEllipsesOutline,
  },
  {
    name: "Settings",
    initialName: "/institution/settings",
    path: "/institution/settings",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/Profile"))
    ),
    roles: ["INSTITUTION"],
    menu: true,
    icon: IoSettingsSharp,
  },
  {
    name: "Update Profile",
    initialName: "/institution/update-profile",
    path: "/institution/update-profile",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/UpdateProfile"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Security",
    initialName: "/institution/security",
    path: "/institution/security",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/Security"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Support",
    initialName: "/institution/support",
    path: "/institution/support",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/Support"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "MyPlan",
    initialName: "/institution/my-plan",
    path: "/institution/my-plan",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/MyPlan"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Pricing",
    initialName: "/institution/pricing",
    path: "/institution/pricing",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/Pricing"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
  {
    name: "Account",
    initialName: "/institution/account",
    path: "/institution/account",
    component: lazy(() =>
      retry(() => import("pages/institution/profile/Account"))
    ),
    roles: ["INSTITUTION"],
    menu: false,
  },
];

export default organizationRoutes;
