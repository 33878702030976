import { Box, Image, Text } from "@chakra-ui/react";
import Error from "../../assets/images/error.png";
import React from "react";

const AppError = ({ error }) => {
  return (
    <Box
      role="alert"
      width={"100%"}
      height={"100vh"}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
      flexDir={"column"}
    >
      <Box width={"300px"}>
        <Image src={Error} display={"flex"} boxSize={"100%"} />
      </Box>

      <Text>Application error</Text>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </Box>
  );
};

export default AppError;
