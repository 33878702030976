import { Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import CreateProfile from "../../../assets/images/create-profile.png";

const Greeting = ({ handleNext }) => {
  return (
    <>
      <VStack
        textAlign={"center"}
        padding={{ mini: 0, tablet: "0 40px",mobile: "20px" }}
        width={"100%"}
        pb={{mini:0,tablet:0,mobile:5}}
      >
        <Text
          fontSize={{mini:"36px",tablet:"36px",mobile:"24px"}}
          fontWeight={600}
          color={"#60A78D"}
          width={{mini:"628px",tablet:"628px",mobile:"100%"}}
        >
          Hi! We are excited to have you join our kindness movement!
        </Text>

        <Text pt={{mini:10,tablet:10,mobile:5}} fontSize={{mini:"26px",tablet:"26px",mobile:"20px"}} fontWeight={600} color={"#737373"}>
          Please take sometime to complete your business details
        </Text>

        <Text
          fontSize={{mini:"26px",tablet:"26px",mobile:"20px"}}
          fontWeight={500}
          color={"#0E1318"}
          width={{ mini: "843px", tablet: "100%" }}
        >
          Let&apos;s complete your profile, once completed our consultants will
          reach out to you
        </Text>

        <Flex width={{mini:"383px",tablet:"383px",mobile:"300px"}} alignItems={"center"} justifyContent={"center"}>
          <Image src={CreateProfile} />
        </Flex>

        <Button
          width={{mini:"391px",tablet:"391px",mobile:"300px"}}
          onClick={() => handleNext()}
          variant={"unstyled"}
          height={"50px"}
          borderRadius={"50px"}
          bg="#60A78D"
          color={"white"}
          colorScheme="green"
        >
          Let&apos;s Go!
        </Button>
      </VStack>
    </>
  );
};

export default Greeting;
