import { lazy } from "react";
import { retry } from "../utils/index";
import { MdStorefront } from "react-icons/md";
import {
  IoChatboxEllipsesOutline,
  IoHandLeftSharp,
  IoSettingsSharp,
} from "react-icons/io5";
import { HiHome } from "react-icons/hi";
import { BsFillBarChartFill, BsFillLightningFill } from "react-icons/bs";
import { ImLibrary } from "react-icons/im";
import { FaDonate, FaHandHoldingHeart } from "react-icons/fa";
import { FaFile } from "react-icons/fa";

const adminRoutes = [
  {
    name: "Dashboard",
    initialName: "/dashboard",
    path: "/dashboard",
    component: lazy(() => retry(() => import("../pages/dashboard"))),
    roles: ["INSTITUTION", "MERCHANT", "ADMIN", "SUPERADMIN"],
    menu: true,
    icon: HiHome,
  },
  {
    name: "Events",
    initialName: "/events",
    path: "/events",
    component: lazy(() => retry(() => import("../pages/events"))),
    roles: ["MERCHANT", "SUPERADMIN"],
    menu: true,
    icon: BsFillLightningFill,
  },
  {
    name: "EventsDetail",
    initialName: "/event-details",
    path: "/event-details/:id",
    component: lazy(() => retry(() => import("../pages/events/event-details"))),
    roles: ["MERCHANT", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "EventDelist",
    initialName: "/event-delist",
    path: "/event-delist/:id",
    component: lazy(() => retry(() => import("../pages/events/event-delist"))),
    roles: ["MERCHANT", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "EventVolunteerList",
    initialName: "/event-volunteer-list",
    path: "/event-volunteer-list/:id",
    component: lazy(() =>
      retry(() => import("../pages/events/event-Volunteer-list"))
    ),
    roles: ["MERCHANT", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "EventFeedback",
    initialName: "/event-feedback",
    path: "/event-feedback/:id",
    component: lazy(() =>
      retry(() => import("../pages/events/event-feedback"))
    ),
    roles: ["MERCHANT", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Organisation",
    initialName: "/organisation",
    path: "/organisation",
    component: lazy(() =>
      retry(() => import("../pages/institution/listing/OrganisationTabs"))
    ),
    roles: ["MERCHANT", "SUPERADMIN"],
    menu: true,
    icon: ImLibrary,
  },
  {
    name: "Organisation",
    initialName: "/organisation",
    path: "/organisation/view/:id",
    component: lazy(() =>
      retry(() => import("../pages/institution/listing/view-applicant-profile"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Organisation",
    initialName: "/organisation",
    path: "/organisation/details/:id",
    component: lazy(() =>
      retry(() => import("../pages/institution/listing/viewOrgDetails"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Organisation",
    initialName: "/organisation",
    path: "/organisation/invoice/:id",
    component: lazy(() =>
      retry(() =>
        import("../pages/institution/listing/Organisation-invoice-details")
      )
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Notification",
    initialName: "/admin/notification",
    path: "/admin/notification",
    component: lazy(() => retry(() => import("../pages/notification"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Organisation",
    initialName: "/organisation",
    path: "/organisation/invoice/report-pdf",
    component: lazy(() =>
      retry(() => import("../pages/institution/reports/pdfReportInvoice"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Organisation",
    initialName: "/organisation",
    path: "/organisation/report-pdf",
    component: lazy(() =>
      retry(() => import("../pages/institution/reports/pdfReport"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant/invoice/report-pdf",
    component: lazy(() =>
      retry(() => import("../pages/merchant/reports/pdfReportInvoice"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant/invoice/:id",
    component: lazy(() =>
      retry(() => import("../pages/merchant/listing/Merchant-invoice-details"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Create",
    initialName: "/create",
    path: "/CreateOrganisationAccount",
    component: lazy(() =>
      retry(() =>
        import("../pages/institution/listing/CreateOrganisationAccount")
      )
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Volunteer",
    initialName: "/volunteer",
    path: "/volunteer",
    component: lazy(() => retry(() => import("../pages/volunteer/index"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: true,
    icon: IoHandLeftSharp,
  },
  {
    name: "Volunteer",
    initialName: "/volunteer",
    path: "/volunteer/details/:id",
    component: lazy(() => retry(() => import("../pages/volunteer/details"))),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant",
    component: lazy(() => retry(() => import("../pages/merchant"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: true,
    icon: MdStorefront,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/add-campaign",
    component: lazy(() =>
      retry(() => import("../pages/merchant/campaign/addCampaign"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Edit Campaign",
    initialName: "/editCampaign",
    path: "merchant/campaign/edit/:id/:outlets",
    component: lazy(() =>
      retry(() =>
        import("../pages/merchant/campaign/editCampaign/editCampaign")
      )
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant/report-pdf",
    component: lazy(() =>
      retry(() => import("../pages/merchant/reports/pdfReport"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/campaign/:id",
    component: lazy(() => retry(() => import("../pages/campaign/CampaignId"))),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/add-outlet",
    component: lazy(() =>
      retry(() => import("../pages/merchant/listing/addMerchant/index"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant/:id",
    component: lazy(() =>
      retry(() => import("../pages/merchant/listing/merchantId"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant/view/:id",
    component: lazy(() =>
      retry(() => import("../pages/merchant/listing/Viewapplicantprofile"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
  {
    name: "Merchant",
    initialName: "/merchant",
    path: "/merchant/add-campaign",
    component: lazy(() =>
      retry(() => import("../pages/merchant/listing/createCampaign"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Barter",
    initialName: "/barters",
    path: "/barters",
    component: lazy(() => retry(() => import("../pages/barter"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: true,
    icon: FaHandHoldingHeart,
  },
  {
    name: "Barter",
    initialName: "/barter-view",
    path: "/barter-view/:id",
    component: lazy(() =>
      retry(() => import("../pages/barter/barter-details"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
    icon: FaHandHoldingHeart,
  },
  {
    name: "Barter",
    initialName: "/barter-delist",
    path: "/barter-delist/:id",
    component: lazy(() => retry(() => import("../pages/barter/barter-delist"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
    icon: FaHandHoldingHeart,
  },
  {
    name: "Fundraising",
    initialName: "/fundraising",
    path: "/fundraising",
    component: lazy(() => retry(() => import("pages/aadminPages/fundraising"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: true,
    icon: FaDonate,
  },
  {
    name: "Fundraising",
    initialName: "/fundraising/applicant/:id",
    path: "/fundraising/applicant/:id",
    component: lazy(() =>
      retry(() =>
        import("pages/aadminPages/fundraising/detail/ApplicantDetail")
      )
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Detail Listing",
    initialName: "/fundraising/detailCampaign",
    path: "/fundraising/detailCampaign",
    component: lazy(() =>
      retry(() => import("pages/aadminPages/fundraising/detail/DetailCampaign"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Donor Listing",
    initialName: "/fundraising/donors",
    path: "/fundraising/donors",
    component: lazy(() =>
      retry(() => import("pages/aadminPages/fundraising/detail/DonorListPage"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Report",
    initialName: "/report",
    path: "/report",
    component: lazy(() => retry(() => import("../pages/reports"))),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: true,
    icon: BsFillBarChartFill,
  },
  {
    name: "Detail Organisation Report",
    initialName: "/organisation-event-list",
    path: "/organisation-event-list",
    component: lazy(() =>
      retry(() => import("../pages/reports/detail/OrganisationEventList"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "Detail Event Report",
    initialName: "/event-volunteer-list",
    path: "/event-volunteer-list",
    component: lazy(() =>
      retry(() => import("../pages/reports/detail/EventVolunteerList"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: false,
  },
  {
    name: "PdfReport",
    initialName: "/report-pdf",
    path: "/report-pdf",
    component: lazy(() => retry(() => import("../pages/reports/pdfReport"))),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Chat",
    initialName: "/chat",
    path: "/chat",
    component: lazy(() => retry(() => import("../pages/messaging/chatbox"))),
    roles: ["INSTITUTION", "ADMIN", "SUPERADMIN"],
    menu: true,
    icon: IoChatboxEllipsesOutline,
  },
  {
    name: "Advertisement",
    initialName: "/advertisement",
    path: "/advertisement",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/advertisement"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
    menu: true,
    icon: FaFile,
  },
  {
    name: "Create Advertisement",
    initialName: "/advertisement/create",
    path: "/advertisement/create",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/component/createAds"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Edit Advertisement",
    initialName: "/advertisement/edit",
    path: "/advertisement/edit",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/component/createAds"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Ads Detail",
    initialName: "/advertisement-detail",
    path: "/advertisement-detail",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/component/AdsDetail"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Transaction Detail",
    initialName: "/advertisement/report/detail",
    path: "/advertisement/report/:id",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/component/TransactionDetail"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Partner Detail",
    initialName: "/partner",
    path: "/partner/:id",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/component/partnerDetail"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Create Partner",
    initialName: "/partner/create",
    path: "/partner/create",
    component: lazy(() =>
      retry(() => import("../pages/advertisement/component/createPartner"))
    ),
    roles: ["ADMIN", "SUPERADMIN"],
  },
  {
    name: "Settings",
    initialName: "/settings",
    path: "/settings",
    component: lazy(() => retry(() => import("../pages/settings/settings"))),
    roles: ["INSTITUTION", "MERCHANT", "ADMIN", "SUPERADMIN"],
    menu: true,
    icon: IoSettingsSharp,
  },
  {
    name: "Settings",
    initialName: "/Settings",
    path: "/settings/:id",
    component: lazy(() =>
      retry(() => import("../pages/settings/commponents/details"))
    ),
    roles: ["ADMIN", "SUPERADMIN", "MERCHANT"],
    menu: false,
  },
];

export default adminRoutes;
