import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useRootContext } from "../../context/RootContext";
import { FiSearch } from "react-icons/fi";
import { BsFillBellFill } from "react-icons/bs";
import { IoSettingsSharp } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SeedsLogo from "../../assets/images/seeds_logo_hor.svg";
import { deleteStorage, getLocalStorage } from "utils";
import endpoint from "service/constUrl";
import service from "service/service";

import TogleSidebar from "../../components/TogleSIdebar/TogleSidebar";
import { HiMenu } from "react-icons/hi";
import MenuItems from "components/Sidemenu/MenuItems";
import { MdClose } from "react-icons/md";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const token = JSON.parse(window.localStorage.getItem("token"));
  const currentLanguage = localStorage.getItem("language");
  const { languageDisclosure, createProfileState } = useRootContext();
  const [userRole] = React.useState(() => getLocalStorage("initialData"));
  const [counter, setCounter] = useState(5);
  const [alertModal, setAlertModal] = useState(false);

  const { setUserSelection, setShowLogin } = useRootContext();

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState(null);

  const [menuOpen,setMenuOpen] = useState(false);

  console.log('menuOpen',menuOpen)

  const handleGetListNotifications = () => {
    setIsLoading(true);

    service
      .get(`${endpoint.BASE_URL}/notification/unread`)
      .then((response) => {
        const { data } = response.data;
        setNotifications(data);
      })
      .catch((error) => {
        const message = error.response.data.message;
        toast({
          title: message ? message : "Server Error",
          position: "top-right",
          status: "error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (
      location.pathname !== "/auth" &&
      location.pathname !== "/" &&
      location.pathname !== "*" &&
      location.pathname !== "/unauthorized"
    ) {
      handleGetListNotifications();
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);

    setTimeout(() => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
        },
        "google_translate_element"
      );
    }, 5000);
  }, []);

  const scrollbarStyle = {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "whitesmoke",
    },
  };

  if (location.pathname === "/auth") {
    return (
      <>
        <Box backgroundColor={"#FFFFFF"}>
          <Flex
            py={"12px"}
            px={createProfileState ? 0 : 10}
            height={"80px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            bg={createProfileState ? "#EDEBEF" : "#FFF"}
            shadow={"md"}
          >
            <Box
              flex={createProfileState ? 1 : "none"}
              width={"180px"}
              onClick={() => navigate("/")}
            >
              <Image
                src={SeedsLogo}
                pl={createProfileState ? 10 : 0}
                maxWidth={"137px"}
              />
            </Box>

            <Flex display={{mini:"none",tablet:"none",mobile:"block"}}>
              <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                cursor="pointer"
                onClick={() => setMenuOpen(true)}
              >
                {!menuOpen ? (<Icon as={HiMenu} w={6} h={6} />) : (<Icon as={MdClose} w={6} h={6} />)}
                
              </Box>
            </Flex>

            <Flex
              flex={createProfileState ? 2 : "none"}
              alignItems={"center"}
              gap={{ mini: 10, laptop: 24, tablet: 4 }}
              display={{mini:"flex",tablet:"flex",mobile:"none"}}
            >
              {createProfileState ? (
                <Box
                  width={"100%"}
                  px={20}
                  display={{ mini: "block", tablet: "none" }}
                >
                  <Text
                    fontSize={"26px"}
                    fontWeight={600}
                    lineHeight={"26px"}
                    color={"#0E1318"}
                  >
                    Complete your profile
                  </Text>
                </Box>
              ) : (
                <>
                  <Link to={"https://socialseeds.asia/volunteer"}>
                    <Text
                      fontWeight={700}
                      fontSize={"16px"}
                      lineHeight={"21px"}
                      color="#0E1318"
                    >
                      Volunteer
                    </Text>
                  </Link>

                  <Link to={"https://socialseeds.asia/institution"}>
                    <Text
                      fontWeight={700}
                      fontSize={"16px"}
                      lineHeight={"21px"}
                      color="#0E1318"
                    >
                      Institution
                    </Text>
                  </Link>

                  <Link to={"https://socialseeds.asia/business"}>
                    <Text
                      fontWeight={700}
                      fontSize={"16px"}
                      lineHeight={"21px"}
                      color="#0E1318"
                    >
                      Business
                    </Text>
                  </Link>

                  <Link to={"https://socialseeds.asia/about-us"}>
                    <Text
                      fontWeight={700}
                      fontSize={"16px"}
                      lineHeight={"21px"}
                      color="#0E1318"
                    >
                      About Us
                    </Text>
                  </Link>

                  <Box
                    color={"#FF7676"}
                    textTransform={"uppercase"}
                    fontWeight={700}
                    fontSize={"14px"}
                    lineHeight={"21px"}
                    display={"flex"}
                    gap={"24px"}
                    alignItems={"center"}
                    // onClick={() => navigate("/auth")}
                  >
                    <Button
                      variant={"unstyled"}
                      color={"#60A78D"}
                      onClick={() => {
                        setUserSelection("");
                        setShowLogin(true);
                      }}
                    >
                      Log In
                    </Button>
                    <Button
                      colorScheme="green"
                      backgroundColor={"#60A78D"}
                      color={"#FFF"}
                      onClick={() => {
                        setUserSelection("");
                        setShowLogin(false);
                      }}
                    >
                      Join Us
                    </Button>
                  </Box>
                </>
              )}
            </Flex>

          </Flex>

          <Divider
            orientation="horizontal"
            borderColor={"#0E6E4B"}
            opacity={"0.2"}
            style={{ filter: blur("0.5px") }}
          />
        </Box>
        <Box
          onClick={() => setMenuOpen(false)}
          display={menuOpen ? "block" : "none"}
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          zIndex={10}
        />
        <Box
          position={"absolute"}
          top={0}
          width={"300px"}
          height={"100vh"}
          left={menuOpen ? 0 : "-100%"}
          ml={menuOpen ? "xs" : 0}
          transition={menuOpen === true ? "ease" : "none"}
          transitionDuration={"0.3s"}
          transitionDelay={"revert"}
          boxShadow={"base"}
          bg={"#EDEBEF"}
          pt={"20px"}
          zIndex={10}
          // display={"none"}
        >
          <Box pos={"relative"} height={"100%"}>
            <Box
              overflowY={"auto"}
              height={"75%"}
              scrollBehavior={"smooth"}
              css={scrollbarStyle}
            >
              <Box onClick={() => window.location.replace('https://socialseeds.asia/volunteer')}>
                <Heading paddingY={3} gap={8} alignItems={"center"} pl={14} width={"100%"} fontWeight={600} fontSize={"md"}>
                  Volunteer
                </Heading>
                <Divider my={1} orientation="horizontal" borderColor={"#60A78D"} opacity={"0.2"} style={{ filter: blur("0.5px") }} />
              </Box>
              <Box onClick={() => window.location.replace('https://socialseeds.asia/volunteer')}>
                <Heading paddingY={3} gap={8} alignItems={"center"} pl={14} width={"100%"} fontWeight={600} fontSize={"md"}>
                  Institution
                </Heading>
                <Divider my={1} orientation="horizontal" borderColor={"#60A78D"} opacity={"0.2"} style={{ filter: blur("0.5px") }} />
              </Box>
              <Box onClick={() => window.location.replace('https://socialseeds.asia/volunteer')}>
                <Heading paddingY={3} gap={8} alignItems={"center"} pl={14} width={"100%"} fontWeight={600} fontSize={"md"}>
                  Business
                </Heading>
                <Divider my={1} orientation="horizontal" borderColor={"#60A78D"} opacity={"0.2"} style={{ filter: blur("0.5px") }} />
              </Box>
              <Box onClick={() => window.location.replace('https://socialseeds.asia/volunteer')}>
                <Heading paddingY={3} gap={8} alignItems={"center"} pl={14} width={"100%"} fontWeight={600} fontSize={"md"}>
                  About Us
                </Heading>
                <Divider my={1} orientation="horizontal" borderColor={"#60A78D"} opacity={"0.2"} style={{ filter: blur("0.5px") }} />
              </Box>
              <Box onClick={() => {
                setUserSelection("")
                setShowLogin(true)
                setMenuOpen(false)
              }}>
                <Heading paddingY={3} gap={8} alignItems={"center"} pl={14} width={"100%"} fontWeight={600} fontSize={"md"}>
                  Login
                </Heading>
                <Divider my={1} orientation="horizontal" borderColor={"#60A78D"} opacity={"0.2"} style={{ filter: blur("0.5px") }} />
              </Box>
              <Box onClick={() => {
                setUserSelection("")
                setShowLogin(false)
                setMenuOpen(false)
              }}>
                <Heading paddingY={3} gap={8} alignItems={"center"} pl={14} width={"100%"} fontWeight={600} fontSize={"md"}>
                  Join Us
                </Heading>
                <Divider my={1} orientation="horizontal" borderColor={"#60A78D"} opacity={"0.2"} style={{ filter: blur("0.5px") }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else if (location.pathname === "/") {
    return (
      <Box>
        <Flex
          px={12}
          height="80px"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box width={"150px"} height={"61px"}>
            <Image src={SeedsLogo} />
          </Box>

          <Flex alignItems={"center"} gap={24}>
            <Link to={"#"}>
              <Text
                fontWeight={700}
                fontSize={"16px"}
                lineHeight={"21px"}
                color="#0E1318"
              >
                Volunteer
              </Text>
            </Link>

            <Link to={"#"}>
              <Text
                fontWeight={700}
                fontSize={"16px"}
                lineHeight={"21px"}
                color="#0E1318"
              >
                Institution
              </Text>
            </Link>

            <Link to={"#"}>
              <Text
                fontWeight={700}
                fontSize={"16px"}
                lineHeight={"21px"}
                color="#0E1318"
              >
                Business
              </Text>
            </Link>

            <Link to={"#"}>
              <Text
                fontWeight={700}
                fontSize={"16px"}
                lineHeight={"21px"}
                color="#0E1318"
              >
                About Us
              </Text>
            </Link>

            <Box
              color={"#FF7676"}
              textTransform={"uppercase"}
              fontWeight={700}
              fontSize={"14px"}
              lineHeight={"21px"}
              display={"flex"}
              gap={"24px"}
              alignItems={"center"}
              onClick={() => navigate("/auth")}
            >
              <Button variant={"unstyled"} color={"#60A78D"}>
                Log In
              </Button>
              <Button
                colorScheme="green"
                backgroundColor={"#60A78D"}
                color={"#FFF"}
              >
                Join Us
              </Button>
            </Box>
          </Flex>
        </Flex>

        <Divider
          orientation="horizontal"
          borderColor={"#FF7676"}
          opacity={"0.2"}
        />
      </Box>
    );
  } else {
    return (
      <>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          boxShadow={"base"}
          bg={"#f8f6fb"}
        >
          <Flex alignItems={"center"} gap={0}>
            <Box
              display={"flex"}
              alignItems={"center"}
              width={{mini:"xs",tablet: "xs",mobile:"100%"}}
              height={"80px"}
              px={12}
            >
              <Image src={SeedsLogo} width={{mini:"150px",tablet: "150px", mobile:"100px"}} height={"61px"} />
            </Box>
          </Flex>

          <Box display={"flex"} alignItems={"center"} px={10}>
            <HStack spacing={5}>
              <Box display={{mini:"block",tablet:"block",mobile:"none"}}>
                <InputGroup display={"flex"} alignItems={"center"}>
                  <InputRightElement height={"100%"} mr={5}>
                    <Icon as={FiSearch} w={6} h={6} />
                  </InputRightElement>
                  <Input
                    type="text"
                    placeholder="Search here..."
                    borderRadius={"58px"}
                    size={"md"}
                  />
                </InputGroup>
              </Box>

              <Box
                onClick={() =>
                  navigate(
                    userRole?.role[0] === "INSTITUTION"
                      ? "/institution/notification"
                      : userRole?.role[0] === "MERCHANT"
                      ? "/merchant/notification"
                      : "/admin/notification"
                  )
                }
                display={"flex"}
                cursor="pointer"
                position="relative"
              >
                <Icon as={BsFillBellFill} w={6} h={6} color={"#717579"} />
                {isLoading ? null : (
                  <Box
                    position="absolute"
                    background="#FFCF6D"
                    color="#fff"
                    p="2px 10px"
                    top="-15px"
                    right="-15px"
                    borderRadius="30px"
                    fontWeight="700"
                    fontSize="12px"
                  >
                    {notifications?.length}
                  </Box>
                )}
              </Box>

              <Box
                onClick={() =>
                  navigate(
                    userRole?.role[0] === "INSTITUTION"
                      ? "/institution/settings"
                      : userRole?.role[0] === "MERCHANT"
                      ? "/settings-merchant"
                      : "/settings"
                  )
                }
                display={"flex"}
                cursor="pointer"
              >
                <Icon as={IoSettingsSharp} w={6} h={6} color={"#717579"} />
              </Box>

              <Box display={{mini:"block",tablet:"block",mobile:"none"}}>
                <div id="google_translate_element" />
              </Box>
            </HStack>
          </Box>
        </Flex>
      </>
    );
  }
};

export default Navbar;
