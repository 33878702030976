import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../../service/service";
import endpoint from "../../../service/constUrl";

export const getMerchants = createAsyncThunk(
  "MERCHANT/GET_MERCHANT",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/merchant`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOutlets = createAsyncThunk(
  "MERCHANT/GET_OUTLETS",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `${endpoint.BASE_URL}/merchant/outlet`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantMembers = createAsyncThunk(
  "MERCHANT/GET_MERCHANT_MEMBERS",
  async (page, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `${endpoint.BASE_URL}/merchant/users/${page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      const { message } = error.response.data;

      if (message.toLowerCase() === "access denied")
        return (window.location.href = "/connect");
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantList = createAsyncThunk(
  "MERCHANT/GET_MERCHANT_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createMerchant = createAsyncThunk(
  "MERCHANT/CREATE_MERCHANT",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/addmerchant`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBusinessType = createAsyncThunk(
  "MERCHANT/CREATE_BUSINESS_TYPE",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/createBusinessType`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessType = createAsyncThunk(
  "MERCHANT/GET_BUSINESS_TYPE",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `${endpoint.BASE_URL}/admin/getbusinesstype`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantApprovalList = createAsyncThunk(
  "MERCHANT/GET_MERCHANT_APPROVAL_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/allapprovals`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantRejectlList = createAsyncThunk(
  "MERCHANT/GET_MERCHANT_REJECT_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/allrejectedapprovals`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantDetails = createAsyncThunk(
  "MERCHANT/GET_MERCHANT_DETAILS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/getbyid`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectMerchantApplication = createAsyncThunk(
  "MERCHANT/REJECT_APPLOCATIONS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/approvalstatus`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantInfo = createAsyncThunk(
  "MERCHANT/GET_MERCHANT_INFO",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `${endpoint.BASE_URL}/admin/merchants/businessname`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsersList = createAsyncThunk(
  "MERCHANT/GET_ALL_USERS_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/users`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "MERCHANT/UPDATE_PASSWORD",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/changepassword`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "MERCHANT/UPDATE_USER_INFO",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants/user`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const suspendedUsersInfo = createAsyncThunk(
  "MERCHANT/SUSPENDED_USER_INFO",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/usersuspended`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCampaignList = createAsyncThunk(
  "MERCHANT/GET_CAMPAIGN_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/getvoucher`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatsData = createAsyncThunk(
  "MERCHANT/GET_STATSDATA",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchant/statistic`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrganizationList = createAsyncThunk(
  "MERCHANT/GET_ORG_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getApprovalList = createAsyncThunk(
  "MERCHANT/GET_ORG_APPROVAL_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/allapprovals`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrganizationDetails = createAsyncThunk(
  "MERCHANT/GET_ORG_DETAILS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/getbyid`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeStatusOrg = createAsyncThunk(
  "MERCHANT/CHANGE_STATUS_ORG",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/approvalstatus`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createOrganization = createAsyncThunk(
  "MERCHANT/CREATE_ORG",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/addorganisation`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrgStatsData = createAsyncThunk(
  "MERCHANT/GET_ORG_STATS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/statistic`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrgUsersList = createAsyncThunk(
  "MERCHANT/GET_ORG_USERS_LIST",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/users`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getORGlist = createAsyncThunk(
  "MERCHANT/GET_ORG_LIST",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `${endpoint.BASE_URL}/admin/organisations/get`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const suspendedOrgUsersInfo = createAsyncThunk(
  "MERCHANT/SUSPENDED_USER_INFO",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/usersuspended`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUOrgserInfo = createAsyncThunk(
  "MERCHANT/UPDATE_USER_INFO",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/userUpdate`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrgUserPassword = createAsyncThunk(
  "MERCHANT/UPDATE_PASSWORD",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.put(
        `${endpoint.BASE_URL}/admin/organisation/changepassword`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInvoices = createAsyncThunk(
  "MERCHANT/GET_INVOICES",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/invoices`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInvoicesDeials = createAsyncThunk(
  "MERCHANT/GET_INVOICES_DETAILS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/invoice/getbyid`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const marKPaidStatus = createAsyncThunk(
  "MERCHANT/GET_MARK_PAID",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/invoice/status`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActiveVouchers = createAsyncThunk(
  "MERCHANT/GET_ACTIVE_VOUCHERS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants/activevoucherlist`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExpiredVouchers = createAsyncThunk(
  "MERCHANT/GET_EXPIRED_VOUCHERS",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants/deactivevoucherlist`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInvoicesMerchant = createAsyncThunk(
  "MERCHANT/GET_INVOICES",
  async (params, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `${endpoint.BASE_URL}/admin/merchants/invoices/${params.status}/${params.page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMerchantInvoicesDeials = createAsyncThunk(
  "MERCHANT/GET_INVOICES",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants/invoice/getbyid`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const marKPaidStatusMerchant = createAsyncThunk(
  "MERCHANT/GET_MARK_PAID",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants/invoice/status`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "MERCHANT/GET_MARK_PAID",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/merchants/user/id`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOrgUserDetails = createAsyncThunk(
  "MERCHANT/GET_MARK_PAID",
  async (initialState, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `${endpoint.BASE_URL}/admin/organisation/user/id`,
        initialState
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);
