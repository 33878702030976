import {
  Box,
  Text,
  Flex,
  Icon,
  VStack,
  useDisclosure,
  Avatar,
  Divider,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
} from "@chakra-ui/react";
import React, { Suspense, useState } from "react";
import { IoLogOut } from "react-icons/io5";
import LogOutModal from "../Modal/AuthModal/LogOutModal";
import AppLoading from "../Error/AppLoading";
import { useSelector } from "react-redux";
import MenuItems from "./MenuItems";
import Logo from "../../assets/images/seeds_logo.svg";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "utils";
import TogleSidebar from "components/TogleSIdebar/TogleSidebar";
import { useRootContext } from "context/RootContext";

const Sidemenu = ({ isOpen }) => {
  const [userRole] = useState(() => getLocalStorage("initialData"));
  const [previewModal, setPreviewModal] = useState({
    image: null,
    status: false,
  });
  const modalDisclosure = useDisclosure();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { sidebarDisclosure } = useRootContext();
  const scrollbarStyle = {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "whitesmoke",
    },
  };

  return (
    <>
      <Box
        onClick={sidebarDisclosure.onToggle}
        display={{ mobile: isOpen ? "block" : "none" ,tablet: isOpen ? "block" : "none", mini: "none" }}
        position={"absolute"}
        top={0}
        left={0}
        width={"100%"}
        height={"100%"}
        backgroundColor={"#0004"}
        zIndex={10}
      />
      <Box
        position={"absolute"}
        top={0}
        width={"xs"}
        height={"100vh"}
        left={isOpen ? 0 : "-100%"}
        ml={isOpen ? "xs" : 0}
        transition={isOpen === true ? "ease" : "none"}
        transitionDuration={"0.3s"}
        transitionDelay={"revert"}
        boxShadow={"base"}
        bg={"#EDEBEF"}
        pt={{ tablet: "100px", mini: "110px", mobile:"100px" }}
        zIndex={10}
      >
        <Box pos={"relative"} height={"100%"}>
          <Suspense fallback={<AppLoading />}>
            <Flex
              pl={14}
              alignItems={"center"}
              fontSize={"20px"}
              fontWeight={600}
              lineHeight={"27px"}
              gap={1}
              mb={5}
            >
              <Flex gap={3} alignItems={"center"}>
                <Box
                  borderRadius={"full"}
                  p={1}
                  className="avatar-outline"
                  bgGradient={
                    "linear-gradient(180deg, #60A78D 0%, rgba(231, 96, 32, 0) 100%)"
                  }
                  width={"58px"}
                  height={"58px"}
                >
                  <Box
                    width={"full"}
                    height={"full"}
                    borderRadius={"full"}
                    overflow={"clip"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    backgroundColor={"#FFF"}
                    onClick={() =>
                      setPreviewModal({
                        image: user?.userData?.detail.photo,
                        status: true,
                      })
                    }
                  >
                    <Image
                      src={
                        user?.userData?.detail?.photo ||
                        user?.userData?.photo ||
                        "https://icare-media-prod.s3.ap-southeast-1.amazonaws.com/1693954469253-165567036.png"
                      }
                      name={user?.userData?.detail?.bussiness_name}
                      alt={user?.userData?.detail?.bussiness_name}
                      width={"58px"}
                      height={"58px"}
                      alignSelf={"center"}
                      objectFit={"scale-down"}
                      borderRadius={"full"}
                    />
                  </Box>
                  {/* <Avatar
                    onClick={() =>
                      setPreviewModal({
                        image: user?.userData?.detail.photo,
                        status: true,
                      })
                    }
                    showBorder={true}
                    src={
                      user?.userData?.detail?.photo ||
                      user?.userData?.photo ||
                      Logo
                    }
                    bg={"white"}
                    cursor={"pointer"}
                  /> */}
                </Box>

                <VStack alignItems={"normal"} spacing={0}>
                  <Text fontSize={"md"} fontWeight={500} translate="no">
                    {user?.userData?.detail?.bussiness_name || "Seeds Admin"}
                  </Text>

                  <HStack>
                    <Text
                      fontSize={"12px"}
                      fontWeight={400}
                      textTransform={"lowercase"}
                      className="roles"
                    >
                      {user?.userData?.roles},
                    </Text>

                    <Text fontSize={"12px"} fontWeight={400}>
                      {user?.userData?.detail?.brand_name}
                    </Text>
                  </HStack>
                </VStack>
              </Flex>
            </Flex>
          </Suspense>

          <Divider
            my={{ laptop: 5, mini: 3, mobile: 3}}
            orientation="horizontal"
            borderColor={"#60A78D"}
            opacity={"0.2"}
            style={{ filter: blur("0.5px") }}
          />
          <Box
            overflowY={"auto"}
            height={{ tablet: "75%", laptop: "80%",mobile: "75%" }}
            scrollBehavior={"smooth"}
            css={scrollbarStyle}
          >
            <MenuItems />
            <Flex
              cursor={"pointer"}
              onClick={modalDisclosure.onToggle}
              gap={7}
              px={10}
              py={5}
              alignItems={"center"}
              pl={14}
              color={"#717579"}
            >
              <Icon
                as={IoLogOut}
                w={{ mini: 6, tablet: 5, mobile: 5 }}
                h={{ mini: 6, tablet: 5, mobile: 5 }}
              />

              <Text fontSize={"md"} fontWeight={500}>
                Log Out
              </Text>
            </Flex>
          </Box>
          <HStack
            paddingY="20px"
            position={"absolute"}
            bottom={0}
            gap={5}
            width={"100%"}
            px={10}
          >
            <Text
              onClick={() =>
                navigate(
                  userRole?.role[0] === "INSTITUTION"
                    ? "/institution/support"
                    : "/support"
                )
              }
              fontSize={"md"}
              color={"#717579"}
              lineHeight={"24px"}
              cursor={"pointer"}
            >
              Support
            </Text>
            <Text
              onClick={() => navigate("/privacy-police")}
              fontSize={"md"}
              color={"#717579"}
              lineHeight={"24px"}
              cursor={"pointer"}
            >
              Privacy
            </Text>
            <Text
              onClick={() => navigate("/term-conditions")}
              fontSize={"md"}
              color={"#717579"}
              lineHeight={"24px"}
              cursor={"pointer"}
            >
              Terms
            </Text>
          </HStack>
        </Box>
      </Box>

      <LogOutModal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
      />

      {/* Image Preview */}
      <Modal
        isOpen={previewModal.status}
        isCentered
        onClose={() => setPreviewModal({ ...previewModal, status: false })}
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor={"transparent"}
          boxShadow={"none"}
          minWidth={"2xl"}
          maxWidth={"3xl"}
        >
          <ModalBody>
            <Image src={previewModal.image} minWidth={"2xl"} maxWidth={"3xl"} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Sidemenu;
