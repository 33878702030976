import React from "react";
import { Helmet } from "react-helmet-async";

const HeadTitle = ({ title }) => {
  return (
    <>
      <Helmet>
        <title translate="no" className="notranslate">
          Seeds {title}
        </title>
      </Helmet>
    </>
  );
};

export default HeadTitle;
