import { Box, Icon } from "@chakra-ui/react";
import React from "react";
import { useRootContext } from "../../context/RootContext";
import { HiMenu } from "react-icons/hi";

const TogleSidebar = ({menu}) => {
  const { sidebarDisclosure } = useRootContext();

  return (
    <Box
      // pb={5}
      display={"flex"}
      alignItems={"center"}
      height={"100%"}
      cursor="pointer"
      onClick={sidebarDisclosure.onToggle}
    >
      <Icon as={HiMenu} w={{mini:10,tablet:10,mobile:6}} h={{mini:10,tablet:10,mobile:6}} />
    </Box>
  );
};

export default TogleSidebar;
