import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Centang from "../../../assets/images/centang.png";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../context/AuthProvider";

import { signOut } from "firebase/auth";
import { authentication } from "../../../firebase/config";
import { deleteStorage } from "../../../utils";
import { persistor } from "../../../app/store";

const Thanks = () => {
  const navigate = useNavigate();
  const { setInitialData, initialData } = useUserAuth();

  const logout = async () => {
    await signOut(authentication);
    deleteStorage("token");
    deleteStorage("initialData");
    persistor.pause();
    persistor.flush().then(() => {
      persistor.purge();
      window.location.href = "/auth";
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (initialData?.roles === 'INSTITUTION') {
        logout()
      } else {
        navigate("/dashboard");
        setInitialData({});
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <VStack textAlign={"center"}>
        <Text
          width={"745px"}
          fontSize={"36px"}
          lineHeight={"42.84px"}
          fontWeight={600}
          color={"#0E6E4B"}
        >
          Thank you for taking the time to complete the application
        </Text>
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Image src={Centang} />
        </Flex>
        <Text
          width={"719px"}
          fontSize={"26px"}
          lineHeight={"42.84px"}
          fontWeight={600}
          color={"#737373"}
        >
          We have received your application and will contact you with 5 business
          days.
        </Text>
        <Text
          pt={10}
          fontSize={"26px"}
          lineHeight={"42.84px"}
          fontWeight={600}
          color={"#737373"}
        >
          Talk soon!
        </Text>
      </VStack>
    </>
  );
};

export default Thanks;
