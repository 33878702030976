import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { signOut } from "firebase/auth";

import { authentication } from "../../../firebase/config";
import { deleteStorage } from "../../../utils";
import { persistor } from "../../../app/store";
import endpoint from "service/constUrl";
import service from "service/service";

const LogOutModal = ({ isOpen, onClose }) => {
  const toast = useToast();

  const logOut = async () => {
    try {
      await service.post(`${endpoint.BASE_URL}/logout`);
      await signOut(authentication);
      deleteStorage("token");
      deleteStorage("initialData");
      persistor.pause();
      persistor.flush().then(() => {
        persistor.purge();
        window.location.href = "/auth";
      });
    } catch (error) {
      const statusCode = error.response.data.statusCode;
      const message = error.response.data.message;
      toast({
        title: message ? message : "Server Error",
        status: "error",
        position: "top-right",
      });
      if (statusCode === 401) {
        await signOut(authentication);
        deleteStorage("token");
        deleteStorage("initialData");
        persistor.pause();
        persistor.flush().then(() => {
          persistor.purge();
          window.location.href = "/auth";
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{mini:"xl",tablet:"xl",mobile:"15rem"}} isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalBody padding={10}>
          <VStack spacing={14}>
            <Text fontSize={"26px"}>Are you sure you want to log out?</Text>

            <HStack>
              <Button
                onClick={onClose}
                width={{mini:"191px",tablet:"191px",mobile:"150px"}}
                height={"50px"}
                borderRadius={"50px"}
              >
                Cancel
              </Button>
              <Button
                onClick={logOut}
                width={{mini:"191px",tablet:"191px",mobile:"150px"}}
                height={"50px"}
                borderRadius={"50px"}
                backgroundColor={"#60A78D"}
                colorScheme="green"
              >
                Yes
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LogOutModal;
