import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialName: "",
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setInitialName: (state, action) => {
      state.initialName = action.payload;
    },
    clearSidebar: (state) => {
      state.initialName = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInitialName, clearSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
