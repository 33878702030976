import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes/routes";
import Layout from "./components/layout/Layout";
import Missing from "./pages/missing";
import Home from "./pages/index";
import Auth from "./pages/auth/index";
import Unauthorized from "./pages/unauthorized";
import RequireAuth from "./components/Auth/RequireAuth";
import AppLoading from "./components/Error/AppLoading";

import TestingUi from "./components/testingUi";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

import "@fontsource/poppins";
import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import { useToast } from "@chakra-ui/react";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const App = () => {
  const vapidKey =
    "BGq8WjA222tbQUdOAlkfSqxP9jtgxLjn_mHFE38SmgXoWdJKm9oj3MSdqOW3i4FPJXuTZ3T2gG9bKl5_HLXTrQY";
  const [fcmToken, setFcmToken] = useState(null);
  const toast = useToast();

  // Handle to get fcm token
  useEffect(() => {
    isSupported()
      .then((isSupported) => {
        if (isSupported) {
          const messaging = getMessaging();

          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              // console.log("Notification permission granted");
              getToken(messaging, { vapidKey: vapidKey })
                .then((currentToken) => {
                  if (currentToken) {
                    // Perform any other neccessary action with the token
                    setFcmToken(currentToken);
                  } else {
                    // Show permission request UI
                    console.log(
                      "No registration token available. Request permission to generate one."
                    );
                  }
                })
                .catch((err) => {
                  console.log(
                    "An error occurred while retrieving token. ",
                    err
                  );
                });
            } else {
              toast({
                title: "Please allow permission for notification",
                position: "top",
                status: "error",
                isClosable: true,
                duration: "5000",
              });
            }
          });
        }
      })
      .catch((e) => alert(e));
  }, []);

  // Handle to get foreground notification
  useEffect(() => {
    isSupported().then((isSupported) => {
      if (isSupported) {
        const messaging = getMessaging();

        onMessage(messaging, (payload) => {
          console.log("Foreground message", payload);

          // Customize the notification appearance and behavior
          const { title, body, imageUrl } = payload.data;
          const options = {
            body,
            icon: imageUrl,
            // Additional custom properties for your notification
          };

          new Notification(title, options);
        });
      }
    });
  }, []);

  return (
    <>
      <Suspense fallback={<AppLoading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth fcmToken={fcmToken} />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/404" element={<Missing />} />

            <Route path="/test" element={<TestingUi />} />

            {routes.map((item, index) => {
              return (
                <Route
                  key={index}
                  element={<RequireAuth allowedRoles={item.roles} />}
                >
                  <Route path={item?.path} element={<item.component />} />
                </Route>
              );
            })}

            <Route path="*" element={<Navigate to={"/404"} />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
