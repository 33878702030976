import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import HeadTitle from "../../components/HeadTitle/HeadTitle";
import Child from "../../assets/images/child.png";
import { useRootContext } from "../../context/RootContext";
import GoogleIcon from "../../assets/images/google-logo.png";
import SGCareLogo from "../../assets/images/sg_cares_logo.svg";
import MerchantStep from "../../components/Auth/SignUp/Merchant/Index";
import OrganizationStep from "../../components/Auth/SignUp/Organization/Index";
import Login from "../../components/Auth/SignIn/Index";
import { useUserAuth } from "../../context/AuthProvider";
import { useForm, Controller } from "react-hook-form";
import { authentication, googleProvider } from "../../firebase/config";
import { signInWithPopup, signOut } from "firebase/auth";
import axios from "axios";
import endpoint from "service/constUrl";

import SeedsLogo from "../../assets/images/seeds_logo.svg";

const Auth = ({ fcmToken }) => {
  const toast = useToast();
  const { setInitialData, initialData } = useUserAuth();
  const {
    userSelection,
    setUserSelection,
    showLogin,
    createProfileState,
    setCreateProfileState,
  } = useRootContext();
  const [showStep, setShowStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [validateResponse, setValidateResponse] = useState(null);
  const [googleAccount, setGoogleAccount] = useState(null);
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useForm();

  const handleValidateEmail = async (email, type = "manual") => {
    try {
      let isValid = true;
      setIsLoading(true);
      const response = await axios.post(`${endpoint.BASE_URL}/validate/email`, {
        email: email,
      });
      const { data } = response;
      if (type === "manual" && data?.status === "error") {
        setValidateResponse(data);
        isValid = false;
      } else if (type === "google" && data?.status === "error") {
        toast({
          duration: 5000,
          position: "bottom-right",
          render: () => (
            <Box
              mb={20}
              bg={"#EDEBEF"}
              color={"#FF6767"}
              borderRadius={"10px"}
              padding={4}
              fontWeight={600}
              border={"1px"}
              borderColor={"#FF6767"}
            >
              {data.message}
            </Box>
          ),
        });
        isValid = false;
      }
      setIsLoading(false);
      setValidateResponse(data);
      return isValid;
    } catch (error) {
      console.log(error);
    }
  };

  const updateFields = (fields) => {
    setInitialData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const onSubmit = async () => {
    updateFields({ email: watch("email") });
    const result = await handleValidateEmail(email);

    if (!errors.email && result) {
      setShowStep(true);
    }
  };

  const handleGoogleLogin = async () => {
    if (watch("roles") === undefined) {
      return setError("roles", {
        type: 400,
        message: "Please select your role",
      });
    }

    await signOut(authentication); // Clear cache on firebase first to select the other account

    const googleProviderCustomed = googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    await signInWithPopup(authentication, googleProviderCustomed)
      .then(async (data) => {
        const isEmailValid = await handleValidateEmail(
          data.user.email,
          "google"
        );
        if (!isEmailValid) return;

        updateFields({ email: data.user.email });
        if (data) {
          setShowStep(true);
          setIsGoogleAccount(true);
          setGoogleAccount(data.user);
        } else {
          toast({
            duration: 5000,
            position: "bottom-right",
            render: () => (
              <Box
                mb={20}
                bg={"#EDEBEF"}
                color={"#60A78D"}
                borderRadius={"10px"}
                padding={4}
                fontWeight={600}
                border={"1px"}
                borderColor={"#60A78D"}
              >
                There&apos;s an error when Sign Up!
              </Box>
            ),
          });
        }
      })
      .catch((error) => console.log(error));

    // console.log(result);
  };

  useEffect(() => {
    email === "" && setValidateResponse(null);
  }, [email]);

  const stepHandler = () => {
    if (showStep === true) {
      if (userSelection === "MERCHANT") {
        return (
          <Box
            flex={{ mini: 2.2 }}
            width={"100%"}
            height={{ mini: "100vh", tablet: "auto", mobile: "auto" }}
          >
            <Flex
              height={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              backgroundColor={"#B3DDCD"}
            >
              <MerchantStep
                isGoogleAccount={isGoogleAccount}
                googleAccount={googleAccount}
                setGoogleAccount={setGoogleAccount}
                fcmToken={fcmToken}
              />
            </Flex>
          </Box>
        );
      } else if (userSelection === "INSTITUTION") {
        return (
          <Box
            flex={{ mini: 2.2 }}
            width={"100%"}
            height={{ mini: "100vh", tablet: "auto", mobile:"auto" }}
            backgroundColor={"#B3DDCD"}
          >
            <Flex
              height={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <OrganizationStep
                isGoogleAccount={isGoogleAccount}
                googleAccount={googleAccount}
                setGoogleAccount={setGoogleAccount}
                fcmToken={fcmToken}
              />
            </Flex>
          </Box>
        );
      } else {
        return;
      }
    } else {
      return (
        <Box
          flex={{ mini: 2.2 }}
          width={"100%"}
          height={{ mini: "100vh", tablet: "auto", mobile:"auto" }}
          backgroundColor={"#B3DDCD"}
          boxShadow={"7px 0px 80px rgba(0, 0, 0, 0.05"}
          padding={{ tablet: "40px", mini: "0", mobile: "40px"}}
        >
          <VStack justifyContent={"center"} height={"100%"}>
            <VStack
              width={{ mini: "625px", tablet: "100%", mobile:"100%" }}
              alignItems={"normal"}
            >
              <Text
                pb={3}
                fontSize={"26px"}
                lineHeight={"20px"}
                fontWeight={600}
                textAlign={{ mini: "left", tablet: "center", mobile:"center" }}
              >
                You are signing up as
              </Text>

              <Text
                fontSize={"16px"}
                lineHeight={"16px"}
                fontWeight={400}
                color={"#737373"}
                textAlign={{ mini: "left", tablet: "center", mobile:"center" }}
              >
                Please select an option below
              </Text>

              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.roles}>
                  <Controller
                    name="roles"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select your role!",
                      },
                    }}
                    render={({ field: { onChange } }) => (
                      <RadioGroup
                        onChange={(e) => {
                          onChange(e);
                          setUserSelection(e);
                          updateFields({ roles: e });
                          clearErrors("roles");
                        }}
                        value={userSelection}
                        pt={8}
                      >
                        <HStack
                          alignItems={"normal"}
                          display={"flex"}
                          justifyContent={{
                            mini: "flex-start",
                            tablet: "center",
                            mobile: "center",
                          }}
                        >
                          <Box
                            width={"185px"}
                            height={"50px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            borderRadius={"50px"}
                            bg={
                              userSelection === "INSTITUTION"
                                ? "#60A78D"
                                : "white"
                            }
                            color={
                              userSelection === "INSTITUTION"
                                ? "white"
                                : "#60A78D"
                            }
                            border={
                              userSelection === "INSTITUTION" ? "none" : "1px"
                            }
                          >
                            <Radio value="INSTITUTION" srOnly>
                              <Text>Organization</Text>
                            </Radio>
                          </Box>

                          <Box
                            width={"185px"}
                            height={"50px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            borderRadius={"50px"}
                            bg={
                              userSelection === "MERCHANT" ? "#60A78D" : "white"
                            }
                            color={
                              userSelection === "MERCHANT" ? "white" : "#60A78D"
                            }
                            border={
                              userSelection === "MERCHANT" ? "none" : "1px"
                            }
                          >
                            <Radio value="MERCHANT" srOnly>
                              <Text>Merchant</Text>
                            </Radio>
                          </Box>
                        </HStack>

                        <FormErrorMessage>
                          {errors.roles && errors.roles.message}
                        </FormErrorMessage>
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                <VStack pt={10} alignItems={"normal"}>
                  <FormControl isInvalid={errors.email}>
                    <FormLabel pb={3}>Email</FormLabel>
                    <InputGroup>
                      <Input
                        id="email"
                        {...register("email", {
                          required: "This fields cannot be empty!",
                        })}
                        type="email"
                        border={"1px"}
                        borderColor={"#60A78D"}
                        placeholder="Enter a valid Email"
                        backgroundColor={"#EDEBEF"}
                        height={"50px"}
                        borderRadius={"50px"}
                        px={8}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <InputRightElement mt={"4px"} mr={"4px"}>
                        {isLoading && <Spinner size={"sm"} color={"#60A78D"} />}
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.email && errors.email.message}
                    </FormErrorMessage>
                    {validateResponse?.status === "error" && (
                      <Text color={"red"} fontSize={"14px"}>
                        {validateResponse?.message}
                      </Text>
                    )}
                  </FormControl>

                  <Text pt={5} pb={10} textAlign={{mini:"left",tablet:"left",mobile:"center"}}>
                    By continuing, you agree and accept to Seeds{" "}
                    <Text as={"span"} fontWeight={700}>
                      Terms of Service
                    </Text>{" "}
                    and acknowledge that you have read and accept our{" "}
                    <Text as={"span"} fontWeight={700}>
                      Privacy Policy.
                    </Text>{" "}
                  </Text>

                  <VStack width={"100%"} alignItems={"normal"} spacing={10}>
                    <Button
                      isDisabled={email === ""}
                      colorScheme="red"
                      type="submit"
                      variant={"unstyled"}
                      height={"50px"}
                      borderRadius={"50px"}
                      bg="#60A78D"
                      color={"white"}
                    >
                      Continue
                    </Button>

                    <Text textAlign={"center"}>OR</Text>

                    <Button
                      onClick={() => handleGoogleLogin()}
                      variant={"unstyled"}
                      height={"50px"}
                      borderRadius={"50px"}
                      bg="white"
                      color={"#737373"}
                    >
                      <Flex justifyContent={"center"} gap={10}>
                        <Image src={GoogleIcon} />
                        <Text>Sign up with Google</Text>
                      </Flex>
                    </Button>
                  </VStack>
                </VStack>
              </form>
            </VStack>
          </VStack>
        </Box>
      );
    }
  };

  return (
    <>
      <HeadTitle title={"Authentication"} />

      <Container
        maxW={"1920px"}
        px={0}
        py={0}
        height={{ mini: "100%", tablet: "auto",mobile:"auto" }}
      >
        <Flex
          justifyContent={"space-between"}
          flexDirection={{ mini: "row", tablet: "column", mobile: "column" }}
          height={"100%"}
          padding={{ tablet: "80px 0 0 0", mini: 0, mobile: "50px 0 0 0" }}
        >
          {/* LEFT ELEMENT */}
          <Box
            flex={{ mini: 1 }}
            position={"relative"}
            width={"100%"}
            height={{ mini: "100vh", tablet: "50%", mobile: "50%" }}
            backgroundColor={"#EDEBEF"}
            style={{ boxShadow: "7px 0px 80px rgba(0, 0, 0, 0.05" }}
            padding={"40px"}
          >
            <VStack
              display={"flex"}
              flexDirection={"column"}
              spacing={{ mini: 5, laptop: 5, mobile:5 }}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              width={"full"}
              position={"relative"}
            >
              <Box width={"164px"}>
                <Image src={SeedsLogo} width={"100%"} />
              </Box>

              <Text
                textAlign={"center"}
                width={"301px"}
                fontSize={{ mini: "md", laptop: "22px", mobile: "20px" }}
                fontWeight={400}
                lineHeight={"28.47px"}
                color={"#0E1318"}
              >
                Take your first step into the world of philanthropy and be the
                driving force for change.
              </Text>
              <Image
                src={SGCareLogo}
                position={{ tablet: "relative", mini: "absolute", mobile: "relative" }}
                bottom={0}
              />
            </VStack>
          </Box>

          {/* RIGHT ELEMENT */}

          {showLogin ? <Login fcmToken={fcmToken} /> : stepHandler()}
        </Flex>

        <Box width={"100%"} bg={createProfileState ? "#EDEBEF" : "transparent"}>
          <Divider
            orientation="horizontal"
            borderColor={"#60A78D"}
            opacity={"0.2"}
            display={{ mini: "block", tablet: "none",mobile: "none" }}
          />

          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={{ mini: "row", tablet: "column-reverse", mobile: "column-reverse" }}
          >
            <Box
              px={{ mini: "49px" }}
              py={{ mini: 5, tablet: 3 }}
              flex={{ mini: 1 }}
              position={"relative"}
              backgroundColor={"#EDEBEF"}
              width={{ tablet: "100%", mini: "auto", mobile:"100%" }}
            >
              <Text
                fontSize={{ mini: "sm", laptop: "lg" }}
                color={"#0E6E4B"}
                lineHeight={"30px"}
                fontWeight={500}
                textAlign={{ mini: "left", tablet: "center", mobile:"center" }}
                py={{mini:0,tablet:0,mobile:5}}
              >
                &copy;{" "}
                {new Date().getFullYear() +
                  " " +
                  "All Rights Reserved, Digital Philanthropy Ltd"}
              </Text>
            </Box>

            <Box
              px={10}
              py={{ mini: 5, tablet: 3, mobile: 5 }}
              display={{mini:"flex",tablet:"flex",mobile:"block"}}
              justifyContent={"space-between"}
              backgroundColor={"#B3DDCD"}
              width={{ tablet: "100%", mini: "auto", mobile: "100%" }}
              flex={showLogin ? 2.2 : 2}
              textAlign={"center"}
            >
              <Text
                fontSize={{ mini: "sm", laptop: "lg" }}
                color={"#0E6E4B"}
                lineHeight={"30px"}
                fontWeight={500}
              >
                Supported by Impact Plus Technologies Pte Ltd
              </Text>
              <Flex gap={10} justifyContent={"center"} mt={{mini:0,tablet:0,mobile:5}}>
                <Link to={"#"}>
                  <Text
                    fontSize={{ mini: "sm", laptop: "lg" }}
                    color={"#0E6E4B"}
                    lineHeight={"30px"}
                    fontWeight={500}
                  >
                    Terms of Use
                  </Text>
                </Link>

                <Link to={"#"}>
                  <Text
                    fontSize={{ mini: "sm", laptop: "lg" }}
                    color={"#0E6E4B"}
                    lineHeight={"30px"}
                    fontWeight={500}
                  >
                    Privacy Policy
                  </Text>
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Container>
    </>
  );
};

export default Auth;
