import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: 'AIzaSyC3FBKZPExoN8sz8c3cDAAT9rIe-nS8Y20',
  authDomain: 'seeds-32f44.firebaseapp.com',
  projectId: 'seeds-32f44',
  storageBucket: 'seeds-32f44.appspot.com',
  messagingSenderId: '819768100442',
  appId: '1:819768100442:web:3ca1a82b59af8ee3c23a63',
  measurementId: 'G-5LSQMK83RW',
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export const authentication = getAuth(app);
export const storage = getStorage();
export const db = getFirestore();
export const googleProvider = new GoogleAuthProvider();
export default app;
