export const handleNext = (next, isLastStep) => {
  if (!isLastStep) {
    return next();
  }
};

export const handleBack = (back, isFirstStep) => {
  if (!isFirstStep) {
    return back();
  }
};

export const goToInputOtp = (currentStepIndex, goTo) => {
  if (currentStepIndex === 0) {
    return goTo(1);
  }
};

export const goToGreeting = (currentStepIndex, goTo) => {
  if (currentStepIndex === 1) {
    return goTo(2);
  }
};
