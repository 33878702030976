import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../../service/service";
import endpoint from "../../../service/constUrl";

export const getTier = createAsyncThunk("TIER/GET_TIER", async (_, { rejectWithValue }) => {
  try {
    const response = await service.get(`${endpoint.BASE_URL}/merchant/tier`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const createTier = createAsyncThunk("TIER/CREATE_TIER", async (initialState, { rejectWithValue }) => {
  try {
    const response = await service.postMultipartWithToken(`${endpoint.BASE_URL}/merchant/tier`, initialState);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteTier = createAsyncThunk("TIER/DELETE_TIER", async (initialState, { rejectWithValue }) => {
  try {
    const response = await service.delete(`${endpoint.BASE_URL}/merchant/tier`, initialState);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateTier = createAsyncThunk("TIER/UPDATE_TIER", async (initialState, { rejectWithValue }) => {
  try {
    const response = await service.put(`${endpoint.BASE_URL}/merchant/tier`, initialState);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
