import { Box } from "@chakra-ui/react";
import React from "react";
import Development from "./development";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {
    window.location.replace('/auth')
  },[])
  return (
    <Box height={"100%"}>
      {/* <Development /> */}
    </Box>
  );
};

export default Index;
