import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../features/auth/authSlice";
import userSlice from "../features/user/userSlice";
import merchantSlice from "../features/merchant/merchantSlice";
import campaignSlice from "../features/campaign/campaignSlice";
import sidebarSlice from "../features/sidebarSlice";
import tierSlice from "../features/tier/tierSlice";

export const rootReducer = combineReducers({
  sideMenu: sidebarSlice,
  auth: authSlice,
  user: userSlice,
  merchant: merchantSlice,
  campaign: campaignSlice,
  tier: tierSlice,
});
