import { useDisclosure } from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";

const RootContext = createContext({});

export const RootContextProvider = ({ children }) => {
  const screenWidth = window.innerWidth;
  const [initialData, setInitialData] = useState({});
  const sidebarDisclosure = useDisclosure({
    defaultIsOpen: screenWidth < 1366 ? false : true,
  });
  const languageDisclosure = useDisclosure();
  const [userSelection, setUserSelection] = useState();
  const [createProfileState, setCreateProfileState] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [childPage, setChildPage] = useState("");
  const modalTierDisclosure = useDisclosure();
  const modalDeleteTier = useDisclosure();

  return (
    <RootContext.Provider
      value={{
        sidebarDisclosure,
        languageDisclosure,
        userSelection,
        setUserSelection,
        createProfileState,
        setCreateProfileState,
        showLogin,
        setShowLogin,
        childPage,
        setChildPage,
        modalTierDisclosure,
        modalDeleteTier,
        initialData,
        setInitialData,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export function useRootContext() {
  return useContext(RootContext);
}
