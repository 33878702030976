import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Controller } from "react-hook-form";
import UploadIcon from "../../../assets/images/cloud_upload_icon.svg";
import PhoneInput from "react-phone-input-2";
import PDFIcon from "../../../assets/images/pdf_icon_green.svg";
import axios from "axios";
import endpoint from "service/constUrl";

const FormMerchant = ({
  handleSubmit,
  errors,
  control,
  logoRef,
  register,
  documentRef,
  updateFields,
  setValue,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedBussinessFile, setSelectedBussinessFile] = useState(null);
  const [phone, setPhone] = useState("");
  const [validateResponse, setValidateResponse] = useState(null);

  const handleValidatePhone = (phone) => {
    setIsLoading(true);
    axios
      .post(`${endpoint.BASE_URL}/validate/phonenumber`, { phonenumber: phone })
      .then((response) => {
        const { data } = response;
        setValidateResponse(data);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleValidatePhone(phone);
    }, 500);

    return () => clearTimeout(timeout);
  }, [phone]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      pb={{mini:24,tablet:24,mobile:10}}
      pt={10}
      pl={{ mini: 20, tablet: 0 }}
      px={{ mini: 0, tablet: "40px" }}
      overflow={"scroll"}
      className="message"
    >
      <form onSubmit={handleSubmit}>
        <VStack spacing={5} alignItems={"normal"}>
          <Text
            fontSize={"26px"}
            fontWeight={600}
            lineHeight={"26px"}
            color={"#0E1318"}
          >
            Complete your profile
          </Text>

          <Box pt={{mini:20,tablet:20,mobile:5}}>
            <FormControl
              isInvalid={errors.photo}
              w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
            >
              <FormLabel
                mb={"39px"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"20px"}
                color={"#0E1318"}
              >
                Upload Merchant Logo <span style={{ color: "#FF7676" }}>*</span>
              </FormLabel>

              <Controller
                name="photo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This fields cannot be empty!",
                  },
                }}
                render={({ field: { onChange } }) => (
                  <Box
                    onClick={() => logoRef.current.click()}
                    height={"138px"}
                    border={"1px"}
                    borderColor={"#60A78D"}
                    borderRadius={"20px"}
                    bg={"white"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Input
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                        setSelectedLogo(e.target.files[0]);
                      }}
                      ref={logoRef}
                      type="file"
                      accept="image/*"
                      srOnly
                    />

                    <VStack>
                      <Image
                        src={
                          selectedLogo === null
                            ? UploadIcon
                            : URL.createObjectURL(selectedLogo)
                        }
                        maxWidth={"72px"}
                      />
                      {selectedLogo === null ? (
                        <>
                          <Text
                            fontWeight={500}
                            fontSize={"16px"}
                            color={"#0E1318"}
                          >
                            Upload or drag & drop your file or image
                          </Text>
                          <Text fontWeight={300} color={"#737373"}>
                            Max size: 5 MB
                          </Text>
                        </>
                      ) : (
                        <Text
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#0E1318"}
                        >
                          Change logo
                        </Text>
                      )}
                    </VStack>
                  </Box>
                )}
              />

              <FormErrorMessage>
                {errors.photo && errors.photo.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <FormControl
            isInvalid={errors.business_name}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Business Name<span style={{ color: "#FF7676" }}>*</span>{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: 300,
                  }}
                >
                  (as per company registration doc)
                </span>
              </FormLabel>

              <Input
                id="business_name"
                {...register("business_name", {
                  required: "This fields cannot be empty!",
                })}
                type="name"
                placeholder="Full Business name"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                // onChange={() => updateFields({ business_name: watch("business_name") })}
              />
            </VStack>

            <FormErrorMessage>
              {errors.business_name && errors.business_name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.brand_name}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Brand Name<span style={{ color: "#FF7676" }}>*</span>{" "}
              </FormLabel>

              <Input
                {...register("brand_name", {
                  required: "This fields cannot be empty!",
                })}
                type="name"
                placeholder="Brand Name"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                // onChange={(e) => updateFields({ brand_name: e.target.value })}
              />
            </VStack>
            <FormErrorMessage>
              {errors.brand_name && errors.brand_name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.date}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Date of Establishment{" "}
                <span style={{ color: "#FF7676" }}>*</span>{" "}
              </FormLabel>

              <Input
                {...register("date", {
                  required: "This fields cannot be empty!",
                })}
                type="date"
                placeholder=" "
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                className="datepicker"
                // onChange={(e) => updateFields({ date: e.target.value })}
              />
            </VStack>
            <FormErrorMessage>
              {errors.date && errors.date.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.bussiness_no}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Company Registration No (UEN)
                <span style={{ color: "#FF7676" }}>*</span>{" "}
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: 300,
                  }}
                >
                  (as per company registration doc)
                </span>
              </FormLabel>

              <Input
                id="bussiness_no"
                {...register("bussiness_no", {
                  required: "This fields cannot be empty!",
                })}
                type="text"
                placeholder="Company Registration No (UEN)"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                // onChange={() => updateFields({ bussiness_no: watch("bussiness_no") })}
              />
            </VStack>

            <FormErrorMessage>
              {errors.bussiness_no && errors.bussiness_no.message}
            </FormErrorMessage>
          </FormControl>

          <Box>
            <FormControl
              isInvalid={errors.document}
              w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
            >
              <FormLabel
                mb={"39px"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"20px"}
                color={"#0E1318"}
              >
                Business Registration Document
                <span style={{ color: "#FF7676" }}>*</span>
              </FormLabel>

              <Controller
                name="document"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This fields cannot be empty!",
                  },
                }}
                render={({ field: { onChange } }) => (
                  <Box
                    onClick={() => documentRef.current.click()}
                    height={"138px"}
                    border={"1px"}
                    borderColor={"#60A78D"}
                    borderRadius={"20px"}
                    bg={"white"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Input
                      ref={documentRef}
                      type="file"
                      srOnly
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                        setSelectedBussinessFile(e.target.files[0]);
                      }}
                    />

                    <VStack>
                      <Image
                        src={
                          selectedBussinessFile === null
                            ? UploadIcon
                            : selectedBussinessFile.name.slice(
                                selectedBussinessFile.name.length - 3,
                                selectedBussinessFile.name.length
                              ) === "pdf"
                            ? PDFIcon
                            : URL.createObjectURL(selectedBussinessFile)
                        }
                        maxWidth={"72px"}
                      />
                      {selectedBussinessFile === null ? (
                        <>
                          <Text
                            fontWeight={500}
                            fontSize={"16px"}
                            color={"#0E1318"}
                          >
                            Upload or drag & drop your file or image
                          </Text>
                          <Text fontWeight={300} color={"#737373"}>
                            Max size: 10 MB
                          </Text>
                        </>
                      ) : (
                        <Text
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#0E1318"}
                        >
                          Change file
                        </Text>
                      )}
                    </VStack>
                  </Box>
                )}
              />

              <FormErrorMessage>
                {errors.document && errors.document.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <FormControl
            isInvalid={errors.country}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Country <span style={{ color: "#FF7676" }}>*</span>
              </FormLabel>

              <Controller
                name="country"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This fields cannot be empty!",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactFlagsSelect
                    selected={value}
                    searchable={true}
                    optionsSize={16}
                    onSelect={(code) => {
                      onChange(code);
                      // console.log(code);
                      // setSelected(code);
                      // updateFields({ country: code });
                    }}
                  />
                )}
              />
            </VStack>
            <FormErrorMessage>
              {errors.country && errors.country.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl w={{ mini: "716px", tablet: "100%",mobile:"100%" }}>
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Phone Number <span style={{ color: "#FF7676" }}>*</span>{" "}
              </FormLabel>

              <PhoneInput
                {...register("phonenumber")}
                country={"sg"}
                onChange={(phone) => {
                  setValue("phonenumber", phone);
                  setPhone(phone);
                }}
              />
              {validateResponse?.status === "error" && (
                <Text color={"red"} fontSize={"14px"}>
                  {validateResponse?.message}
                </Text>
              )}
            </VStack>
          </FormControl>
          <FormControl
            isInvalid={errors.address}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Merchant Address <span style={{ color: "#60A78D" }}>*</span>
              </FormLabel>

              <Input
                id="address"
                placeholder="Address Line 1"
                {...register("address.0", {
                  required: "This fields cannot be empty!",
                })}
                type="text"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
              />
            </VStack>

            <FormErrorMessage>
              {errors.address && errors.address.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={errors.address2}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <Input
                id="address2"
                placeholder="Address Line 2"
                {...register("address.1", {
                  required: "This fields cannot be empty!",
                })}
                type="text"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
              />
            </VStack>

            <FormErrorMessage>
              {errors.address2 && errors.address2.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={errors.postalcode}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Postal Code <span style={{ color: "#60A78D" }}>*</span>
              </FormLabel>

              <Input
                id="postalcode"
                placeholder="Postal code"
                {...register("postalcode", {
                  required: "This fields cannot be empty!",
                })}
                type="number"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
              />
            </VStack>

            <FormErrorMessage>
              {errors.postalcode && errors.postalcode.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.business_type}>
            <Controller
              name="business_type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "This fields cannot be empty!",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
                  onChange={(e) => {
                    onChange(e);
                    // updateFields({ business_type: e });
                  }}
                  value={value}
                >
                  <VStack alignItems={"normal"}>
                    <FormLabel fontSize={"20px"} fontWeight={600}>
                      Business Type<span style={{ color: "#FF7676" }}>*</span>{" "}
                    </FormLabel>

                    <Flex alignItems={"center"} gap={20}>
                      <VStack spacing={4} alignItems={"normal"}>
                        <Radio
                          value="retail"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Retail
                        </Radio>
                        <Radio
                          value="fastfood"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Fastfood
                        </Radio>
                        <Radio
                          value="restaurant"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Restaurant
                        </Radio>

                        <Radio
                          value="hotel"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Hotel
                        </Radio>
                      </VStack>

                      <VStack spacing={4} alignItems={"normal"}>
                        <Radio
                          value="supermart"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Supermart
                        </Radio>
                        <Radio
                          value="kopitam"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Kopitiam
                        </Radio>
                        <Radio
                          value="travel"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Travel
                        </Radio>
                        <Radio
                          value="attractions"
                          size="lg"
                          colorScheme="green"
                          backgroundColor={"#FFF"}
                          boxShadow={"0px 3px 10px rgba(0, 0, 0, 0.16)"}
                        >
                          Attractions
                        </Radio>
                      </VStack>
                    </Flex>
                  </VStack>
                </RadioGroup>
              )}
            />

            <FormErrorMessage>
              {errors.business_type && errors.business_type.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.number_outlet}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Number of Outlets
              </FormLabel>

              <Input
                {...register("number_outlet")}
                type="number"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                // onChange={(e) => updateFields({ number_of_outlets: e.target.value })}
              />
            </VStack>

            {/* <FormErrorMessage>{errors.number_outlet && errors.number_outlet.message}</FormErrorMessage> */}
          </FormControl>

          <FormControl
            isInvalid={errors.email_or_contact}
            w={{ mini: "716px", tablet: "100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Email or contact number
                <span style={{ color: "#FF7676" }}>*</span>{" "}
              </FormLabel>

              <Input
                {...register("email_or_contact", {
                  required: "This fields cannot be empty!",
                })}
                type="name"
                placeholder="Person In Charge email or contact number"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                // onChange={(e) => updateFields({ email_or_contact: e.target.value })}
              />
            </VStack>
            <FormErrorMessage>
              {errors.email_or_contact && errors.email_or_contact.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={errors.business_profile}
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Business Profile<span style={{ color: "#FF7676" }}>*</span>{" "}
              </FormLabel>

              <Textarea
                {...register("business_profile", {
                  required: "This field cannot be empty!",
                })}
                placeholder="Write a short description about your business."
                height={"202px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                p={5}
                // onChange={(e) => updateFields({ business_profile: e.target.value })}
              />
            </VStack>
            <FormErrorMessage>
              {errors.business_profile && errors.business_profile.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
            isInvalid={errors.website}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Website
                <span style={{ color: "#FF7676" }}>*</span>
              </FormLabel>

              <Input
                {...register("website", {
                  required: "This field cannot be empty!",
                })}
                type="text"
                placeholder="Website Address"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                onChange={(e) => updateFields({ website: e.target.value })}
              />
            </VStack>
            <FormErrorMessage>
              {errors.website && errors.website.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            w={{ mini: "716px", tablet: "100%",mobile:"100%" }}
            isInvalid={errors.link}
          >
            <VStack alignItems={"normal"}>
              <FormLabel fontSize={"20px"} fontWeight={600}>
                Social Media
                <span style={{ color: "#FF7676" }}>*</span>
              </FormLabel>

              <Input
                {...register("link", {
                  required: "This field cannot be empty!",
                })}
                type="text"
                placeholder="Social media handles"
                height={"56px"}
                borderRadius={"10px"}
                boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
                bg={"white"}
                px={5}
                onChange={(e) => updateFields({ link: e.target.value })}
              />
            </VStack>
            <FormErrorMessage>
              {errors.link && errors.link.message}
            </FormErrorMessage>
          </FormControl>

          <Box>
            <Button
              type="submit"
              width={{mini:"342px",tablet:"342px",mobile:"100%"}}
              height={"53px"}
              borderRadius={"50px"}
              colorScheme="green"
              backgroundColor={"#60A78D"}
            >
              Submit application
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
};

export default FormMerchant;
