import { lazy } from "react";
import { retry } from "../utils/index";
import {
  BsFillLightningFill,
  BsTicketFill,
  BsFillGridFill,
} from "react-icons/bs";
import { MdBarChart } from "react-icons/md";
import { ImFileText2 } from "react-icons/im";
import { HiHome } from "react-icons/hi";
import { IoSettingsSharp } from "react-icons/io5";

const merchantRoutes = [
  // Dashboard Page
  {
    name: "Dashboard",
    initialName: "/dashboard",
    path: "/dashboard",
    component: lazy(() => retry(() => import("../pages/dashboard"))),
    roles: ["INSTITUTION", "MERCHANT", "ADMIN", "SUPERADMIN"],
    menu: true,
    icon: HiHome,
  },

  // Campaign Page
  {
    name: "Campaign",
    initialName: "/campaign",
    path: "/campaign",
    component: lazy(() =>
      retry(() => import("../pages/merchant/campaign/campaign"))
    ),
    roles: ["MERCHANT"],
    menu: true,
    icon: BsFillLightningFill,
  },

  // Bonus Page
  {
    name: "Bonus",
    initialName: "/bonus",
    path: "/vouchers",
    component: lazy(() =>
      retry(() => import("../pages/merchant/bonus/MerchantBonus"))
    ),
    roles: ["MERCHANT"],
    menu: true,
    icon: BsTicketFill,
  },

  // Report Page
  {
    name: "Reports",
    initialName: "/reports",
    path: "/reporting",
    component: lazy(() =>
      retry(() => import("../pages/merchant/report/MerchantReport"))
    ),
    roles: ["MERCHANT"],
    menu: true,
    icon: MdBarChart,
  },

  // Connect Page
  {
    name: "Connect",
    initialName: "/connect",
    path: "/connect",
    component: lazy(() => retry(() => import("../pages/connect/connect"))),
    roles: ["MERCHANT"],
    menu: true,
    icon: BsFillGridFill,
  },

  // Invoice Page
  {
    name: "Invoice",
    initialName: "/invoice",
    path: "/invoices",
    component: lazy(() =>
      retry(() => import("../pages/merchant/invoice/MerchantInvoice"))
    ),
    roles: ["MERCHANT"],
    menu: true,
    icon: ImFileText2,
  },

  // Setting Page
  {
    name: "Settings",
    initialName: "/settings-merchant",
    path: "/settings-merchant",
    component: lazy(() =>
      retry(() => import("../pages/merchant/settings/MerchantSettings"))
    ),
    roles: ["MERCHANT"],
    menu: true,
    icon: IoSettingsSharp,
  },
  {
    name: "Support",
    initialName: "/support",
    path: "/support",
    component: lazy(() =>
      retry(() => import("../pages/merchant/support/Support"))
    ),
    roles: ["MERCHANT"],
    menu: false,
  },
  {
    name: "PrivacyPolice",
    initialName: "/privacy-police",
    path: "/privacy-police",
    component: lazy(() =>
      retry(() => import("../pages/merchant/support/PrivacyPolice"))
    ),
    roles: ["INSTITUTION", "MERCHANT"],
    menu: false,
  },
  {
    name: "TnC",
    initialName: "/term-conditions",
    path: "/term-conditions",
    component: lazy(() => retry(() => import("../pages/merchant/support/TnC"))),
    roles: ["INSTITUTION", "MERCHANT"],
    menu: false,
  },

  // Notification Page
  {
    name: "Notification",
    initialName: "/merchant/notification",
    path: "/merchant/notification",
    component: lazy(() =>
      retry(() => import("../pages/merchant/notification/MerchantNotification"))
    ),
    roles: ["MERCHANT"],
    menu: false,
  },
];

export default merchantRoutes;
