import { createSlice } from "@reduxjs/toolkit";
import { getTier, createTier, deleteTier, updateTier } from "./tierService";

const initialState = {
  loading: null,
  tier: [],
  data: [],
  isAdd: false,
  isDelete: false,
  isUpdate: false,
  error: {},
};

export const tierSlice = createSlice({
  name: "tier",
  initialState,
  reducers: {
    clearTier: (state) => {
      state.tier = [];
      state.tierById = [];
    },
    onHideTier: (state) => {
      state.isAdd = false;
      state.isDelete = false;
      state.isUpdate = false;
      state.error = {};
      state.tier = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // GET TIER
      .addCase(getTier.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTier.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getTier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // CREATE TIER
      .addCase(createTier.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTier.fulfilled, (state, action) => {
        state.loading = false;
        state.tier = action.payload;
        state.isAdd = true;
      })
      .addCase(createTier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // DELETE TIER
      .addCase(deleteTier.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTier.fulfilled, (state, action) => {
        state.loading = false;
        state.tier = action.payload;
        state.isDelete = true;
      })
      .addCase(deleteTier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // UPDATE TIER
      .addCase(updateTier.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTier.fulfilled, (state, action) => {
        state.loading = false;
        state.tier = action.payload;
        state.isUpdate = true;
      })
      .addCase(updateTier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearTier, onHideTier } = tierSlice.actions;

export default tierSlice.reducer;
