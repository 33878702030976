import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar.jsx";
import { Box, Container } from "@chakra-ui/react";
import { useRootContext } from "../../context/RootContext.js";
import Sidemenu from "../../components/Sidemenu/Sidemenu.jsx";
import { signOut } from "firebase/auth";
import { authentication } from "../../firebase/config.js";
import { deleteStorage, getLocalStorage } from "utils/index.js";
import { persistor } from "app/store.js";
import AlertErrorModal from "components/Modal/AlertErrorModal/index.jsx";
import { useEffect, useState } from "react";

const Layout = () => {
  const location = useLocation();
  const { sidebarDisclosure } = useRootContext();
  const isAuthorized = getLocalStorage("isAuthorized");
  const [token] = useState(() => getLocalStorage("token"));
  const [counter, setCounter] = useState(5);
  const [alertModal, setAlertModal] = useState(false);

  // rendering for handle countdown and auto-logout
  useEffect(() => {
    if (token && isAuthorized === "false") {
      setAlertModal(true);

      const interval = setInterval(() => {
        counter > 0 && setCounter((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isAuthorized, counter]);

  // render to auto logout and clear local data
  useEffect(() => {
    if (alertModal && counter === 0) {
      setAlertModal(false);
      signOut(authentication);
      deleteStorage("token");
      deleteStorage("initialData");
      persistor.pause();
      persistor.flush().then(() => {
        persistor.purge();
        window.location.href = "/auth";
      });
    }
  }, [counter]);

  return (
    <>
      {location.pathname === "/greeting" ||
      location.pathname === "/unauthorized" ||
      location.pathname === "/404" ? null : (
        <Container
          maxW={"1920px"}
          px={0}
          pos={"fixed"}
          left={0}
          right={0}
          zIndex={20}
          transition={sidebarDisclosure.isOpen === true ? "all" : "none"}
          transitionDuration={"0.2s"}
          transitionDelay={"initial"}
        >
          <Navbar />
        </Container>
      )}

      <Container
        position={"relative"}
        maxW={"1920px"}
        transition={sidebarDisclosure.isOpen === true ? "all" : "none"}
        transitionDuration={"0.2s"}
        transitionDelay={"initial"}
        bg={"#f8f6fb"}
        pl={{
          tablet: 0,
          mini: () => {
            if (
              location.pathname === "/auth" ||
              location.pathname === "/" ||
              location.pathname === "/greeting" ||
              location.pathname === "/unauthorized" ||
              location.pathname === "/404"
            ) {
              return 0;
            } else if (sidebarDisclosure.isOpen) {
              return "20rem";
            } else {
              return 0;
            }
          },
          mobile: 0,
        }}
        pr={0}
      >
        <Box
          position={"relative"}
          className="message"
          overflow={"scroll"}
          height={"100vh"}
          zIndex={10}
          pt={
            location.pathname === "/auth" ||
            location.pathname === "/unauthorized"
              ? 0
              : "80px"
          }
          borderRight="1px solid #e2e8f0"
        >
          <Box
            position={"relative"}
            width={"100%"}
            height={"100%"}
            px={location.pathname === "/auth" ? 0 : 5}
            pt={location.pathname === "/auth" ? 0 : 5}
          >
            <Outlet />
          </Box>
        </Box>

        {location.pathname === "/" ||
        location.pathname === "/404" ||
        location.pathname === "/auth" ||
        location.pathname === "/unauthorized" ? null : (
          <Sidemenu
            isOpen={sidebarDisclosure.isOpen}
            onClose={sidebarDisclosure.onClose}
          />
        )}
      </Container>
      <AlertErrorModal alertModal={alertModal} counter={counter} />
    </>
  );
};

export default Layout;
