import { persistor } from "../app/store";
import routes from "../routes/routes";
import { Text } from "@chakra-ui/react";

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log("Item or key not provided");
  }
}

export function getLocalStorage(key) {
  try {
    const value = window.localStorage.getItem(key);
    return JSON.parse(value);
  } catch (e) {
    console.log("Item or key not provided");
  }
}

export function deleteStorage(key) {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.log("Item or key not provided");
  }
}

let censorWord = function (str) {
  return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
};

export const censorEmail = (email) => {
  var arr = email.split("@");
  return censorWord(arr[0]) + "@" + censorWord(arr[1]);
};

export const getPageName = (fontSize, fontWeight) => {
  for (let index = 0; index < routes.length; index++) {
    // const element = routes[index].path;
    if (location.pathname === routes[index].path) {
      return (
        <>
          <Text fontWeight={fontWeight} fontSize={fontSize}>
            {routes[index].name}
          </Text>
        </>
      );
    }
  }
  // console.log(routes[index].name);
};

export const logOutEffect = () => {
  persistor.pause();
  persistor.flush().then(() => {
    persistor.purge();
    window.location.href = "/auth";
  });
};
