import { mode } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const breakpoints = {
  mobile: "300px",
  tablet: "640px",
  medium: "900px",
  mini: "1366px",
  laptop: "1920px",
  desktop: "1925px",
};

const styles = {
  global: (props) => ({
    body: {
      bg: mode("#f8f6fb", "#1d1d1d")(props),
    },
  }),
};

const fonts = {
  body: `'Poppins', sans-serif`,
};

const colors = {
  background: {
    100: "#FFFFFF",
    200: "#EDEBEF",
  },
  green: {
    primary: "#20493B",
  },
  orange: {
    primary: {
      100: "#E76020",
      200: "#EBDDD6",
      300: "#FEE0D1",
    },
  },
  pink: {
    primary: "#FF7676",
  },
  gray: {
    primary: {
      100: "#888A99",
      200: "#737373",
    },
  },
  heading: {
    100: "#0E1318",
  },
  redPrimary: {
    500: "#60A78D",
  },
};

const Divider = {
  defaultProps: { size: "md" },
  sizes: {
    lg: { borderWidth: "10px" },
    md: { borderWidth: "2px" },
    sm: { borderWidth: "1px" },
  },
};

const Table = {
  variants: {
    tier: {
      th: {
        lineHeight: "20px",
        backgroundColor: "#D9D9D9",
        textAlign: "center",
      },
      td: {
        border: "1px solid",
        borderColor: "#EDEBEF",
        textAlign: "center",
      },
      tr: {
        border: "1px solid",
        borderColor: "#EDEBEF",
        textAlign: "center",
      },
    },
  },
};

const Modal = {
  variants: {
    tier: {
      dialog: {
        backgroundColor: "#D9D9D9",
        padding: 12,
        borderRadius: "20px",
      },
    },
  },
};

const Checkbox = {
  baseStyle: {
    control: {
      width: "40px", // Set the width of the checkbox box
      height: "40px", // Set the height of the checkbox box
      background: "transparent",
    },
  },
};

const theme = extendTheme({
  config,
  styles,
  fonts,
  colors,
  breakpoints,
  components: {
    Divider,
    Table,
    Modal,
    // Checkbox,
  },
});

export default theme;
