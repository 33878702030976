import {
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";

function AlertErrorModal({ alertModal, counter }) {
  return (
    <Modal isOpen={alertModal} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={"80%"}
        width={"654px"}
        height={"400px"}
        borderRadius={"20px"}
        border={"1px solid #60A78D"}
      >
        <ModalBody width={"100%"} height={"100%"} padding={"32px"}>
          <Flex
            width={"100%"}
            height={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"12px"}
          >
            <Icon as={FiAlertCircle} w={24} h={24} color={"red.400"} />
            <Flex flexDirection={"column"} width={"100%"} alignItems={"center"}>
              <Text
                maxWidth={"70%"}
                fontSize={"24px"}
                color={"#60A78D"}
                fontWeight={600}
                textAlign={"center"}
              >
                Your account already logged in on another device
              </Text>
              <Text
                fontSize={"18px"}
                color={"#737373"}
                fontWeight={400}
                textAlign={"center"}
              >
                auto-logout after:
              </Text>
            </Flex>
            <Text
              fontSize={"72px"}
              color={"#60A78D"}
              fontWeight={500}
              textAlign={"center"}
            >
              {counter}
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AlertErrorModal;
