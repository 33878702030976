import axios from "axios";
import { setLocalStorage } from "../utils";

class Service {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.token = JSON.parse(window.localStorage.getItem("token"));
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    if (
      error.response.data.message === "Subscription required" &&
      error.response.data.statusCode === 402
    )
      return (window.location.href = "/institution/pricing");

    // handle network error
    if (
      error?.message === "Network request failed" ||
      error?.message === "Network Error"
    ) {
      alert("Network Error", "Please check your connection", "iconNoInet");
    }

    if (error?.status === 502) {
      alert("Internal server error");
    }

    // handle error access
    if (error.response.status === 401) {
      setLocalStorage("isAuthorized", "false");
    }

    return Promise.reject(error);
  };

  get(path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getWithToken(path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Bearer ${params}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getArrayBuffer(path, params, callback) {
    return this.service
      .get(path, {
        params,
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getBlob(path, params, callback) {
    return this.service
      .get(path, {
        params,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postBlob(path, params, callback) {
    return this.service
      .post(path, {
        params,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  patch(path, payload, callback) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  put(put, payload, callback) {
    return this.service
      .request({
        method: "PUT",
        url: put,
        responseType: "json",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  putMultipartWithToken(path, payload, callback) {
    return this.service
      .request({
        method: "PUT",
        url: path,
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  post(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWithoutToken(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postMultipart(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postMultipartWithToken(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postArrayBuffer(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "arraybuffer",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWithoutHeader(path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  delete(path, payload, callback) {
    return this.service
      .request({
        method: "DELETE",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWithToken(token, path, payload, callback) {
    return this.service
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }
}

export default new Service();
