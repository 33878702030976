import { Box, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useUserAuth } from "../../../context/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { censorEmail } from "../../../utils";
import { sendOtp, verifyOtp } from "../../../app/features/auth/authService";
import { clearData } from "../../../app/features/auth/authSlice";

const InputOtp = ({ handleNext }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { initialData } = useUserAuth();
  const [otp, setOtp] = useState("");

  const auth = useSelector((state) => state.auth);

  const verify = () => {
    const data = {
      email: initialData.email,
      roles: initialData.role,
      otp_token: otp,
    };

    dispatch(verifyOtp(data));
  };

  const handleClick = () => {
    return dispatch(sendOtp({ email: initialData.email, roles: initialData.roles }));
  };

  useEffect(() => {
    dispatch(sendOtp({ email: initialData.email, roles: initialData.roles }));
  }, []);

  useEffect(() => {
    if (otp.length >= 6) {
      verify();
    }
  }, [otp]);

  useEffect(() => {
    if (auth?.data === undefined) {
      toast({
        duration: 5000,
        position: "bottom-right",
        render: () => (
          <Box
            mb={20}
            bg={"#EDEBEF"}
            color={"#FF7676"}
            borderRadius={"10px"}
            padding={4}
            fontWeight={600}
            border={"1px"}
            borderColor={"#FF7676"}
          >
            {auth?.data?.message ? auth?.data?.message : "There's and error, please validate your otp"}
          </Box>
        ),
      });
    }
  }, [auth?.data]);

  useEffect(() => {
    if (auth?.data?.success) {
      dispatch(clearData());
      handleNext();
    }
  }, [auth?.data?.success]);

  return (
    <>
      <Box width={{mini:"614px",tablet:"614px",mobile:"100%"}}>
        <VStack alignItems={"normal"} spacing={8}>
          <Box p={{mobile:5}}>
            <Text fontSize={"26px"} fontWeight="600" color={"#0E1318"}>
              Two Step Verification
            </Text>

            <Text fontSize={"14px"} fontWeight={400} lineHeight={"21.2px"} color={"#737373"}>
              To sign up for a new account, we request a verification code from your email for additional security.
            </Text>
          </Box>

          <Text fontSize={"14px"} fontWeight={400} lineHeight={"24px"} color={"#0E1318"} p={{mobile:"0px 20px"}}>
            Please enter the 6 digit verification code we send to your email at {censorEmail(initialData.email)} This
            code will expire after 20 minutes.
          </Text>

          <Box pt={{mini:10,tablet:10,mobile:5}} alignSelf={"center"}>
            <OtpInput
              inputStyle="inputStyle"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
            />
          </Box>

          <Text onClick={() => handleClick()} textAlign={"center"} textDecor={"underline"} fontSize={"16px"}>
            Resend Code
          </Text>
        </VStack>
      </Box>
    </>
  );
};

export default InputOtp;
