import { createSlice } from "@reduxjs/toolkit";
import { createCampaigns } from "./campaignService";

const initialState = {
  loading: null,
  data: [],
  isAdd: null,
};

export const campaignSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    clearCampaign: (state) => {
      state.data = [];
    },
    removeAdd: (state) => {
      state.isAdd = false;
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // CREATE CAMPAIGN
      .addCase(createCampaigns.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCampaigns.fulfilled, (state) => {
        state.loading = false;
        // state.data = action.payload;
        state.isAdd = true;
      })
      .addCase(createCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearCampaign, removeAdd } = campaignSlice.actions;

export default campaignSlice.reducer;
