import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useRootContext } from "../../../context/RootContext";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Controller, useForm } from "react-hook-form";
import { authSignIn, verifyOtp } from "../../../app/features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import { setLocalStorage } from "../../../utils";
import { setUser } from "../../../app/features/user/userSlice";
import { clearData } from "../../../app/features/auth/authSlice";
import GoogleIcon from "../../../assets/images/google-logo.png";
import { signInWithPopup, signOut } from "firebase/auth";
import {
  authentication,
  googleProvider,
  storage,
} from "../../../firebase/config";
import axios from "axios";
import endpoint from "service/constUrl";
import OTPInput from "react-otp-input";

const Index = ({ fcmToken }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { userSelection, setUserSelection, setShowLogin } = useRootContext();
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [forgotModal, setForgotModal] = useState();
  const [otpModal, setOtpModal] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    newPassword: "",
    retypePassword: "",
  });
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [timeZone, setTimeZone] = useState(null);

  const auth = useSelector((state) => state?.auth);
  const user = useSelector((state) => state?.user);

  const handleGetOtpPassword = () => {
    setIsLoading(true);
    axios
      .post(`${endpoint.BASE_URL}/send_reset_password`, {
        email: verifiedEmail,
      })
      .then((response) => {
        const { status } = response.data;
        if (status === "success") {
          toast({
            title: response.data.message,
            status: "success",
            position: "top",
            duration: 5000,
          });
          setOtpModal(true);
        }
        if (status === "error") {
          toast({
            title: response.data.message,
            status: "error",
            position: "top",
            duration: 5000,
          });
        }
      })
      .catch((error) =>
        toast({
          title: error.message,
          status: "error",
          position: "top",
          duration: 5000,
        })
      )
      .finally(() => setIsLoading(false));
  };

  const verify = () => {
    const data = {
      email: verifiedEmail,
      otp_token: otpValue,
    };
    axios
      .post(`${endpoint.BASE_URL}/verify_otp`, data)
      .then((response) => {
        const { status } = response.data;
        if (status === "success") {
          toast({
            title: response.data.message,
            status: "success",
            position: "top",
            duration: 5000,
          });
          setNewPasswordModal(true);
          setOtpModal(false);
        }
        if (status === "error") {
          toast({
            title: response.data.message,
            status: "error",
            position: "top",
            duration: 5000,
          });
        }
      })
      .catch((error) =>
        toast({
          title: error.message,
          status: "error",
          position: "top",
          duration: 5000,
        })
      );
  };

  const handleResetPassword = () => {
    const body = {
      email: verifiedEmail,
      otpcode: parseInt(otpValue),
      password: passwordInput.newPassword,
    };
    if (passwordInput.newPassword !== passwordInput.retypePassword) {
      toast({
        title: "Please retype new password correctly",
        status: "error",
        position: "top",
        duration: 5000,
      });
    } else {
      setIsLoading(true);
      axios
        .post(`${endpoint.BASE_URL}/verification_password`, body)
        .then((response) => {
          const { status } = response.data;
          if (status === "success") {
            toast({
              title: "Your password has been changed",
              status: "success",
              position: "top",
              duration: 5000,
            });
            setNewPasswordModal(false);
            setVerifiedEmail("");
            setOtpValue("");
            setForgotModal(false);
            setPasswordInput({ newPassword: "", retypePassword: "" });
          }
          if (status === "error") {
            toast({
              title: response.data.message,
              status: "error",
              position: "top",
              duration: 5000,
            });
          }
        })
        .catch((error) =>
          toast({
            title: error.message,
            status: "error",
            position: "top",
            duration: 5000,
          })
        )
        .finally(() => setIsLoading(false));
    }
  };

  const handleGetGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          const { message } = error;
          toast({
            title: message,
            status: "error",
            position: "top",
            duration: 5000,
          });
        }
      );
    } else {
      toast({
        title: "Geolocation is not supported by this browser.",
        status: "error",
        position: "top",
        duration: 5000,
      });
    }
  };

  const handleGetUserTimezone = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(userTimezone);
  };

  useEffect(() => {
    handleGetGeoLocation();
    handleGetUserTimezone();
  }, []);

  useEffect(() => {
    otpValue.length === 6 && verify();
  }, [otpValue]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm();

  const onSubmit = () => {
    const data = {
      email: watch("email"),
      password: watch("password"),
      fcm_token: fcmToken,
      timezone: timeZone,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
    };
    dispatch(authSignIn(data));
    dispatch(clearData());
  };

  const handleGoogleLogin = async () => {
    if (watch("roles") === undefined) {
      return setError("roles", {
        type: 400,
        message: "Please select your role",
      });
    }

    const googleProviderCustomed = googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    await signInWithPopup(authentication, googleProviderCustomed)
      .then((data) => {
        if (data) {
          const userDatas = {
            email: data.user.email,
            password: "",
            fcm_token: fcmToken,
            provider: "Google",
            timezone: timeZone,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            data_provider: JSON.stringify(data.user),
          };
          dispatch(authSignIn(userDatas));
          dispatch(clearData());
        } else {
          toast({
            duration: 5000,
            position: "bottom-right",
            render: () => (
              <Box
                mb={20}
                bg={"#EDEBEF"}
                color={"#60A78D"}
                borderRadius={"10px"}
                padding={4}
                fontWeight={600}
                border={"1px"}
                borderColor={"#60A78D"}
              >
                There&apos;s an error when Sign Up!
              </Box>
            ),
          });
        }
      })
      .catch((error) => console.log(error));

    // console.log(result);
  };

  useEffect(() => {
    if (
      auth.data?.status === "error" ||
      auth.error?.message === "Rejected" ||
      auth.error?.message === "Pending"
    ) {
      toast({
        duration: 5000,
        position: "bottom-right",
        render: () => (
          <Box
            mb={20}
            bg={"#EDEBEF"}
            color={"#60A78D"}
            borderRadius={"10px"}
            padding={4}
            fontWeight={600}
            border={"1px"}
            borderColor={"#60A78D"}
          >
            {auth?.data?.message
              ? auth?.data?.message
              : "There's and error when sign in"}
          </Box>
        ),
      });
      dispatch(clearData());
    }
  }, [auth.data, auth.error]);

  useEffect(() => {
    if (auth.data?.status === "success") {
      setLocalStorage("initialData", {
        role: [auth.data?.data?.roles],
      });
      localStorage.setItem("userData", JSON.stringify(auth.data?.data));
      setUserSelection("");
      setLocalStorage("token", auth?.data?.data?.secret_token);
      setLocalStorage("isAuthorized", "true");
      setToken(auth?.data?.data?.secret_token);
    }
  }, [auth.data]);

  useEffect(() => {
    if (token !== null) {
      dispatch(setUser(auth?.data?.data));
      dispatch(clearData());
    }
  }, [token, dispatch, setUser]);

  useEffect(() => {
    if (user?.userData?.status === true) {
      window.location.href = "/dashboard";
    }
    if (window.location.search == "?signUP") {
      setShowLogin(false);
      setUserSelection("");
    } else if (window.location.search == "?signIN") {
      setShowLogin(true);
      setUserSelection("");
    }
  }, [user?.userData?.status]);

  return (
    <Box flex={{ mini: 2.2 }} padding={"20px"} bg={"#B3DDCD"}>
      <VStack alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"}>
        <Text
          fontWeight={600}
          fontSize={"26px"}
          lineHeight={"26px"}
          color={"#0E1318"}
          textAlign={"center"}
        >
          Good to see you again
        </Text>

        <Text
          fontWeight={500}
          fontSize={"18px"}
          lineHeight={"27px"}
          color={"#737373"}
        >
          Please select your role
        </Text>

        <FormControl
          justifyContent={"center"}
          width={"625px"}
          pt={8}
          isInvalid={errors.roles}
        >
          <Flex placeContent="center">
            <Controller
              name="roles"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "This fields cannot be empty!",
                },
              }}
              render={({ field: { onChange } }) => (
                <RadioGroup
                  onChange={(e) => {
                    onChange(e);
                    setUserSelection(e);
                  }}
                  value={userSelection}
                >
                  <Flex alignItems={"center"} spacing={5} flexDirection={{mini:"row",tablet:"row",mobile:"column"}} gap={5}>
                    <Box
                      width={"185px"}
                      height={"50px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"50px"}
                      bg={
                        userSelection === "ORGANIZATION" ? "#60A78D" : "white"
                      }
                      color={
                        userSelection === "ORGANIZATION" ? "white" : "#60A78D"
                      }
                      border={userSelection === "ORGANIZATION" ? "none" : "1px"}
                    >
                      <Radio p={0} m={0} srOnly value="ORGANIZATION">
                        <Text>Institution</Text>
                      </Radio>
                    </Box>

                    <Box
                      width={"185px"}
                      height={"50px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"50px"}
                      bg={userSelection === "MERCHANT" ? "#60A78D" : "white"}
                      color={userSelection === "MERCHANT" ? "white" : "#60A78D"}
                      border={userSelection === "MERCHANT" ? "none" : "1px"}
                    >
                      <Radio srOnly value="MERCHANT">
                        <Text>Merchant</Text>
                      </Radio>
                    </Box>

                    <Box
                      width={"185px"}
                      height={"50px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"50px"}
                      bg={userSelection === "ADMIN" ? "#60A78D" : "white"}
                      color={userSelection === "ADMIN" ? "white" : "#60A78D"}
                      border={userSelection === "ADMIN" ? "none" : "1px"}
                    >
                      <Radio srOnly value="ADMIN">
                        <Text>Seeds Admin</Text>
                      </Radio>
                    </Box>
                  </Flex>
                </RadioGroup>
              )}
            />
          </Flex>

          <FormErrorMessage>
            {errors.roles && errors.roles.message}
          </FormErrorMessage>
        </FormControl>
        <Box width={{mini:"625px",tablet:"625px",mobile:"100%"}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack pt={10} spacing={5}>
              <FormControl isInvalid={errors.email}>
                <FormLabel pb={3}>Email</FormLabel>
                <Input
                  id="email"
                  {...register("email", {
                    required: "This fields cannot be empty!",
                  })}
                  type="email"
                  border={"1px"}
                  borderColor={"#60A78D"}
                  placeholder="Enter a valid Email"
                  backgroundColor={"#EDEBEF"}
                  height={"50px"}
                  borderRadius={"50px"}
                  px={8}
                />

                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.password}>
                <FormLabel pb={3}>Password</FormLabel>

                <InputGroup display="flex" alignItems="center" gap={5}>
                  <Input
                    {...register("password", {
                      required: "This fields cannot be empty!",
                      minLength: {
                        value: 6,
                        message: "Password length must be 6 or more",
                      },
                    })}
                    id="password"
                    placeholder="Enter 12 character password"
                    px={8}
                    backgroundColor={"#EDEBEF"}
                    border={"1px"}
                    borderColor={"#60A78D"}
                    height={"50px"}
                    borderRadius={"50px"}
                    type={showPassword ? "text" : "password"}
                  />

                  <InputRightElement height={"100%"} pr={8}>
                    <Icon
                      as={showPassword ? AiOutlineEyeInvisible : AiOutlineEye}
                      w={8}
                      h={8}
                      cursor="pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>

            <Flex
              width={{mini:"625px",tablet:"625px",mobile:"100%"}}
              alignItems={"flex-end"}
              flexDir={"column"}
              gap={5}
            >
              <Text px={3} fontSize={"16px"} fontWeight={400} pt={4}>
                <Button
                  variant={"unstyled"}
                  // as={"span"}
                  fontWeight={500}
                  onClick={() => setForgotModal(true)}
                >
                  Forgot password?
                </Button>{" "}
                |{" "}
                <Button
                  variant={"unstyled"}
                  isDisabled={userSelection === "ADMIN"}
                  onClick={() => {
                    setShowLogin(false);
                    setUserSelection("");
                  }}
                  // as={"span"}
                  fontWeight={500}
                >
                  Sign Up?
                </Button>
              </Text>

              <Flex width={"full"} direction={"column"} gap={"26px"}>
                <Button
                  type="submit"
                  width={"full"}
                  height={"50px"}
                  borderRadius={"50px"}
                  colorScheme="green"
                  backgroundColor={"#60A78D"}
                  // isLoading={auth?.loading}
                  loadingText="Submitting"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  Sign in
                </Button>
                <Text color={"#0E1318"} textAlign={"center"} fontSize={"16px"}>
                  OR
                </Text>
                <Button
                  onClick={() => handleGoogleLogin()}
                  variant={"unstyled"}
                  height={"50px"}
                  borderRadius={"50px"}
                  bg="white"
                  color={"#737373"}
                >
                  <Flex justifyContent={"center"} gap={10}>
                    <Image src={GoogleIcon} />
                    <Text>Sign in with Google</Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </form>
        </Box>
      </VStack>

      {/* Forgot Password Modal */}
      <Modal
        isOpen={forgotModal}
        onClose={() => setForgotModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={"10px"} padding={"20px"} maxWidth={"560px"}>
          <ModalHeader textAlign={"center"} fontSize={"24px"} fontWeight={700}>
            Forgot Password
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"} gap={"20px"}>
            <Text textAlign={"center"}>
              Enter the email address associated with your account,
              <br />
              we will send you 6 digit verification code.
            </Text>
            <Input
              type="email"
              borderRadius={"10px"}
              height={"50px"}
              placeholder="Email address"
              backgroundColor={"#EDEBEF"}
              onChange={(e) => setVerifiedEmail(e.target.value)}
            />
          </ModalBody>

          <ModalFooter display={"flex"} gap={"12px"} justifyContent={"center"}>
            <Button
              colorScheme="green"
              variant={"outline"}
              height={"50px"}
              width={"191px"}
              borderRadius={"full"}
              border={"1px solid #60A78D"}
              onClick={() => setForgotModal(false)}
            >
              Cancel
            </Button>
            <Button
              isDisabled={verifiedEmail === ""}
              isLoading={isLoading}
              colorScheme="green"
              height={"50px"}
              width={"191px"}
              borderRadius={"full"}
              border={"1px solid #60A78D"}
              backgroundColor={"#60A78D"}
              onClick={handleGetOtpPassword}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* OTP Modal */}
      <Modal isOpen={otpModal} onClose={() => setOtpModal(false)} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={"10px"} padding={"20px"} maxWidth={"560px"}>
          <ModalHeader textAlign={"center"} fontSize={"24px"} fontWeight={700}>
            Two Step Verification
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            width={"full"}
            flexDirection={"column"}
            justifyContent={"center"}
            gap={"20px"}
          >
            <Text textAlign={"center"}>
              Please enter the 6 digit verification code we send to your email.
              <br />
              This code will expire after 20 minutes.
            </Text>
            <Box>
              <OTPInput
                containerStyle={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
                inputStyle={{
                  border: "1px solid #60A78D40",
                  backgroundColor: "#EDEBEF",
                  width: "60px",
                  height: "80px",
                  borderRadius: "10px",
                  boxShadow: "1px 4px #f5f5f5",
                }}
                value={otpValue}
                onChange={setOtpValue}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
            </Box>
            <Text
              onClick={handleGetOtpPassword}
              textAlign={"center"}
              textDecor={"underline"}
              fontSize={"16px"}
              cursor={"pointer"}
            >
              Resend Code
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* New Password */}
      <Modal
        isOpen={newPasswordModal}
        onClose={() => setNewPasswordModal(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={"10px"} padding={"20px"} maxWidth={"560px"}>
          <ModalHeader textAlign={"center"} fontSize={"24px"} fontWeight={700}>
            Create New Password
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"} gap={"20px"}>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={isShow ? "text" : "password"}
                  borderRadius={"10px"}
                  height={"50px"}
                  placeholder="New Password"
                  backgroundColor={"#EDEBEF"}
                  onChange={(e) =>
                    setPasswordInput({
                      ...passwordInput,
                      newPassword: e.target.value,
                    })
                  }
                />
                <InputRightElement width="4.5rem" mt={2}>
                  <Icon
                    as={isShow ? AiOutlineEyeInvisible : AiOutlineEye}
                    w={8}
                    h={8}
                    cursor="pointer"
                    onClick={() => setIsShow(!isShow)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Re-type Password</FormLabel>
              <InputGroup>
                <Input
                  borderRadius={"10px"}
                  height={"50px"}
                  placeholder="New Password"
                  backgroundColor={"#EDEBEF"}
                  onChange={(e) =>
                    setPasswordInput({
                      ...passwordInput,
                      retypePassword: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter display={"flex"} gap={"12px"} justifyContent={"center"}>
            <Button
              isDisabled={
                passwordInput.newPassword === "" ||
                passwordInput.retypePassword === ""
              }
              isLoading={isLoading}
              colorScheme="green"
              height={"50px"}
              width={"191px"}
              borderRadius={"full"}
              border={"1px solid #60A78D"}
              backgroundColor={"#60A78D"}
              onClick={handleResetPassword}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Index;
