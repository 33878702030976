import { Box, Image } from "@chakra-ui/react";
import MissingPage from "../assets/images/404.png";
import React from "react";

const Missing = () => {
  return (
    <Box width={"100%"} height={"100%"} display="flex" justifyContent="center">
      <Image src={MissingPage} />
    </Box>
  );
};

export default Missing;
